/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The CartComparisonDebugCheapestBasketItems model module.
 * @module model/CartComparisonDebugCheapestBasketItems
 * @version v1
 */
export class CartComparisonDebugCheapestBasketItems {
  /**
   * Constructs a new <code>CartComparisonDebugCheapestBasketItems</code>.
   * @alias module:model/CartComparisonDebugCheapestBasketItems
   * @class
   * @param qty {Number} 
   * @param sellable_id {String} 
   * @param price_in_cents {Number} 
   */
  constructor(qty, sellable_id, price_in_cents) {
    this.qty = qty;
    this.sellable_id = sellable_id;
    this.price_in_cents = price_in_cents;
  }

  /**
   * Constructs a <code>CartComparisonDebugCheapestBasketItems</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CartComparisonDebugCheapestBasketItems} obj Optional instance to populate.
   * @return {module:model/CartComparisonDebugCheapestBasketItems} The populated <code>CartComparisonDebugCheapestBasketItems</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CartComparisonDebugCheapestBasketItems();
      if (data.hasOwnProperty('qty'))
        obj.qty = ApiClient.convertToType(data['qty'], 'Number');
      if (data.hasOwnProperty('sellable_id'))
        obj.sellable_id = ApiClient.convertToType(data['sellable_id'], 'String');
      if (data.hasOwnProperty('price_in_cents'))
        obj.price_in_cents = ApiClient.convertToType(data['price_in_cents'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} qty
 */
CartComparisonDebugCheapestBasketItems.prototype.qty = undefined;

/**
 * @member {String} sellable_id
 */
CartComparisonDebugCheapestBasketItems.prototype.sellable_id = undefined;

/**
 * @member {Number} price_in_cents
 */
CartComparisonDebugCheapestBasketItems.prototype.price_in_cents = undefined;

