/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {OrderItems} from './OrderItems';

/**
 * The Order model module.
 * @module model/Order
 * @version v1
 */
export class Order {
  /**
   * Constructs a new <code>Order</code>.
   * @alias module:model/Order
   * @class
   * @param id {String} 
   */
  constructor(id) {
    this.id = id;
  }

  /**
   * Constructs a <code>Order</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Order} obj Optional instance to populate.
   * @return {module:model/Order} The populated <code>Order</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Order();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('collection_date'))
        obj.collection_date = ApiClient.convertToType(data['collection_date'], 'String');
      if (data.hasOwnProperty('formatted_collection_date'))
        obj.formatted_collection_date = ApiClient.convertToType(data['formatted_collection_date'], 'String');
      if (data.hasOwnProperty('created_at'))
        obj.created_at = ApiClient.convertToType(data['created_at'], 'String');
      if (data.hasOwnProperty('order_events'))
        obj.order_events = ApiClient.convertToType(data['order_events'], [Object]);
      if (data.hasOwnProperty('order_items'))
        obj.order_items = OrderItems.constructFromObject(data['order_items']);
      if (data.hasOwnProperty('provider'))
        obj.provider = ApiClient.convertToType(data['provider'], 'String');
      if (data.hasOwnProperty('reference'))
        obj.reference = ApiClient.convertToType(data['reference'], 'String');
      if (data.hasOwnProperty('requires_shipping'))
        obj.requires_shipping = ApiClient.convertToType(data['requires_shipping'], 'Boolean');
      if (data.hasOwnProperty('shipping_provider'))
        obj.shipping_provider = ApiClient.convertToType(data['shipping_provider'], 'String');
      if (data.hasOwnProperty('dispatch_method'))
        obj.dispatch_method = ApiClient.convertToType(data['dispatch_method'], 'String');
      if (data.hasOwnProperty('shipping_status'))
        obj.shipping_status = ApiClient.convertToType(data['shipping_status'], 'String');
      if (data.hasOwnProperty('shipping_price_in_cents'))
        obj.shipping_price_in_cents = ApiClient.convertToType(data['shipping_price_in_cents'], 'Number');
      if (data.hasOwnProperty('shipping_address'))
        obj.shipping_address = ApiClient.convertToType(data['shipping_address'], Object);
      if (data.hasOwnProperty('status'))
        obj.status = ApiClient.convertToType(data['status'], 'String');
      if (data.hasOwnProperty('stock_short'))
        obj.stock_short = ApiClient.convertToType(data['stock_short'], 'Boolean');
      if (data.hasOwnProperty('subtotal_in_cents'))
        obj.subtotal_in_cents = ApiClient.convertToType(data['subtotal_in_cents'], 'Number');
      if (data.hasOwnProperty('discount_code'))
        obj.discount_code = ApiClient.convertToType(data['discount_code'], 'String');
      if (data.hasOwnProperty('discount_in_cents'))
        obj.discount_in_cents = ApiClient.convertToType(data['discount_in_cents'], 'Number');
      if (data.hasOwnProperty('supplier_id'))
        obj.supplier_id = ApiClient.convertToType(data['supplier_id'], 'String');
      if (data.hasOwnProperty('supplier_name'))
        obj.supplier_name = ApiClient.convertToType(data['supplier_name'], 'String');
      if (data.hasOwnProperty('supplier_address'))
        obj.supplier_address = ApiClient.convertToType(data['supplier_address'], 'String');
      if (data.hasOwnProperty('total'))
        obj.total = ApiClient.convertToType(data['total'], 'String');
      if (data.hasOwnProperty('total_in_cents'))
        obj.total_in_cents = ApiClient.convertToType(data['total_in_cents'], 'Number');
      if (data.hasOwnProperty('payable_balance_in_cents'))
        obj.payable_balance_in_cents = ApiClient.convertToType(data['payable_balance_in_cents'], 'Number');
      if (data.hasOwnProperty('debtorcard_balance'))
        obj.debtorcard_balance = ApiClient.convertToType(data['debtorcard_balance'], 'String');
      if (data.hasOwnProperty('requires_bags'))
        obj.requires_bags = ApiClient.convertToType(data['requires_bags'], 'Boolean');
      if (data.hasOwnProperty('bag_qty'))
        obj.bag_qty = ApiClient.convertToType(data['bag_qty'], 'Number');
      if (data.hasOwnProperty('bag_total_in_cents'))
        obj.bag_total_in_cents = ApiClient.convertToType(data['bag_total_in_cents'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
Order.prototype.id = undefined;

/**
 * @member {String} collection_date
 */
Order.prototype.collection_date = undefined;

/**
 * @member {String} formatted_collection_date
 */
Order.prototype.formatted_collection_date = undefined;

/**
 * @member {String} created_at
 */
Order.prototype.created_at = undefined;

/**
 * @member {Array.<Object>} order_events
 */
Order.prototype.order_events = undefined;

/**
 * @member {module:model/OrderItems} order_items
 */
Order.prototype.order_items = undefined;

/**
 * @member {String} provider
 */
Order.prototype.provider = undefined;

/**
 * @member {String} reference
 */
Order.prototype.reference = undefined;

/**
 * @member {Boolean} requires_shipping
 */
Order.prototype.requires_shipping = undefined;

/**
 * @member {String} shipping_provider
 */
Order.prototype.shipping_provider = undefined;

/**
 * @member {String} dispatch_method
 */
Order.prototype.dispatch_method = undefined;

/**
 * @member {String} shipping_status
 */
Order.prototype.shipping_status = undefined;

/**
 * @member {Number} shipping_price_in_cents
 */
Order.prototype.shipping_price_in_cents = undefined;

/**
 * @member {Object} shipping_address
 */
Order.prototype.shipping_address = undefined;

/**
 * @member {String} status
 */
Order.prototype.status = undefined;

/**
 * @member {Boolean} stock_short
 */
Order.prototype.stock_short = undefined;

/**
 * @member {Number} subtotal_in_cents
 */
Order.prototype.subtotal_in_cents = undefined;

/**
 * @member {String} discount_code
 */
Order.prototype.discount_code = undefined;

/**
 * @member {Number} discount_in_cents
 */
Order.prototype.discount_in_cents = undefined;

/**
 * @member {String} supplier_id
 */
Order.prototype.supplier_id = undefined;

/**
 * @member {String} supplier_name
 */
Order.prototype.supplier_name = undefined;

/**
 * @member {String} supplier_address
 */
Order.prototype.supplier_address = undefined;

/**
 * @member {String} total
 */
Order.prototype.total = undefined;

/**
 * @member {Number} total_in_cents
 */
Order.prototype.total_in_cents = undefined;

/**
 * @member {Number} payable_balance_in_cents
 */
Order.prototype.payable_balance_in_cents = undefined;

/**
 * @member {String} debtorcard_balance
 */
Order.prototype.debtorcard_balance = undefined;

/**
 * @member {Boolean} requires_bags
 */
Order.prototype.requires_bags = undefined;

/**
 * @member {Number} bag_qty
 */
Order.prototype.bag_qty = undefined;

/**
 * @member {Number} bag_total_in_cents
 */
Order.prototype.bag_total_in_cents = undefined;

