import { DateTime } from 'luxon'
import currency from 'currency.js'

export default {
  as_json: (object) -> JSON.parse(JSON.stringify(object))
  number_to_currency: (integer) -> (integer / 100).toFixed(2)
}

export as_json = (object) -> JSON.parse(JSON.stringify(object))

# options = { cents: integer, symbol: string }
export number_to_currency = (options) ->
  currency(options.cents, {
    fromCents: true,
    symbol: options.symbol,
    separator: ' ',
    pattern: "! #"
  }).format()

export format_date_less_one = (date) ->
  return false if !date
  DateTime.fromISO(date).setZone('Africa/Johannesburg').minus({ days: 1 }).toFormat('dd LLL')

export formatted_datetime = (date, format) ->
  DateTime.fromISO(date).setZone('Africa/Johannesburg').toFormat(format)

export build_formatted_timeslot = ({ day_slot, end, start }) ->
  formatted_date = start.toFormat('ccc, dd LLL yyyy')
  formatted_start = start.toFormat('t')
  formatted_end = end.toFormat('t')
  if day_slot
    formatted_date
  else
    "#{formatted_date}, #{formatted_start} - #{formatted_end}"
