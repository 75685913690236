
export default {
  computed: {
    visible: -> @$store.state.layout.account_required_modal

    target_path: -> @$route.path

    login_url: -> "/sign_in?return_to=#{@target_path}"

    register_url: -> "/register?return_to=#{@target_path}"

  },
  methods: {
    dismiss: ->
      @$store.commit('layout/show_account_required', false)
  }
}
