/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {Order} from './Order';
import {OrderModifications} from './OrderModifications';

/**
 * The OrderModificationsValidationResponse model module.
 * @module model/OrderModificationsValidationResponse
 * @version v1
 */
export class OrderModificationsValidationResponse {
  /**
   * Constructs a new <code>OrderModificationsValidationResponse</code>.
   * @alias module:model/OrderModificationsValidationResponse
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>OrderModificationsValidationResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/OrderModificationsValidationResponse} obj Optional instance to populate.
   * @return {module:model/OrderModificationsValidationResponse} The populated <code>OrderModificationsValidationResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new OrderModificationsValidationResponse();
      if (data.hasOwnProperty('out_of_stock'))
        obj.out_of_stock = ApiClient.convertToType(data['out_of_stock'], 'Boolean');
      if (data.hasOwnProperty('updated'))
        obj.updated = ApiClient.convertToType(data['updated'], 'Boolean');
      if (data.hasOwnProperty('payable_amount_error'))
        obj.payable_amount_error = ApiClient.convertToType(data['payable_amount_error'], 'String');
      if (data.hasOwnProperty('notice'))
        obj.notice = ApiClient.convertToType(data['notice'], 'String');
      if (data.hasOwnProperty('order'))
        obj.order = Order.constructFromObject(data['order']);
      if (data.hasOwnProperty('modifications'))
        obj.modifications = OrderModifications.constructFromObject(data['modifications']);
    }
    return obj;
  }
}

/**
 * @member {Boolean} out_of_stock
 */
OrderModificationsValidationResponse.prototype.out_of_stock = undefined;

/**
 * @member {Boolean} updated
 */
OrderModificationsValidationResponse.prototype.updated = undefined;

/**
 * @member {String} payable_amount_error
 */
OrderModificationsValidationResponse.prototype.payable_amount_error = undefined;

/**
 * @member {String} notice
 */
OrderModificationsValidationResponse.prototype.notice = undefined;

/**
 * @member {module:model/Order} order
 */
OrderModificationsValidationResponse.prototype.order = undefined;

/**
 * @member {module:model/OrderModifications} modifications
 */
OrderModificationsValidationResponse.prototype.modifications = undefined;

