import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from '../plugins/logger';
import account from './modules/account';
import root_categories from './modules/root_categories';
import category from './modules/category';
import product from './modules/product';
import sellable_filter from './modules/sellable_filter';
import offers from './modules/offers';
import cart from './modules/cart';
import checkout from './modules/checkout';
import layout from './modules/layout';
import suppliers from './modules/suppliers';
import catalog from './modules/catalog';
import banners from './modules/banners';
import category_banners from './modules/category_banners';
import leaflets from './modules/leaflets';
import orders from './modules/orders';
import address_picker from './modules/address_picker';
import application from './modules/application';
import bootstate from './modules/bootstate'
import agencies from './modules/agencies'
import analytics from './modules/analytics'

Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== 'production';
const debug = false

// remove supermarket-client localstorage
localStorage.removeItem('cart-items.json')
localStorage.removeItem('account.json')
localStorage.removeItem('cached.json')

export const store = new Vuex.Store({
  modules: {
    account,
    address_picker,
    agencies,
    analytics,
    application,
    bootstate,
    root_categories,
    category,
    product,
    sellable_filter,
    offers,
    cart,
    checkout,
    layout,
    suppliers,
    catalog,
    banners,
    category_banners,
    leaflets,
    orders
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
});
