
export default {
  computed: {
    order_pending_review: ->
      @$store.getters['orders/pending_review'][0]

    show_order_review_comment: ->
      0 < @rating < 5

    supplier_name: -> @order_pending_review?.supplier_name
  }

  data: -> {
    loading: false,
    rating: 0,
    review_comment: null,
    show_order_review: false
  }

  methods: {
    submit_order_review: ->
      @loading = true
      review = {
        comment: @review_comment,
        order_id: @order_pending_review.id,
        rating: @rating
      }
      @$store.dispatch('orders/post_order_review', review)
        .then (response) => @show_order_review = false
        .catch (error) -> console.error 'post_order_review.error', error
        .finally => @loading = false
  }

  watch: {
    order_pending_review: (order) ->
      @show_order_review = !!order
  }
}
