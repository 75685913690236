
import { DateTime } from 'luxon'
import { formatted_datetime } from '@/lib/helpers'
import { number_to_currency } from '@/lib/helpers'

export default {
  computed: {
    dispatch_method: -> @$store.state.orders.current?.dispatch

    dispatch_date: -> @$store.state.orders.current?.collection_date

    order_reference: -> @$store.state.orders.current?.reference

    visible: ->
      @$store.state.checkout.show_outro

    order: -> @$store.state.orders.current

    supplier_name: -> @$store.state.orders.current?.supplier_name

    collection_date: ->
      formatted_datetime(@dispatch_date, 'dd LLL y')

    collection_time: ->
      formatted_datetime(@dispatch_date, 'T')

    dispatch_text: ->
      if @order && @order.requires_shipping
        return @$t('checkout_outro_manual_delivery') if @order.shipping_provider == 'manual_delivery'
        @$t('checkout_outro_delivery', { supplier_name: @supplier_name, collection_date: @collection_date, collection_time: @collection_time })
        # "It will be <span class='r---weight-600'>delivered</span> from <span class='r---weight-600'>#{@supplier_name}</span> on <span class='r---weight-600'>#{@collection_date} after #{@collection_time}</span>"
      else if @has_provider('cash')
        @$t('checkout_outro_collection_unpaid', { supplier_name: @supplier_name, collection_date: @collection_date, collection_time: @collection_time })
      else
        @$t('checkout_outro_collection_paid', { supplier_name: @supplier_name, collection_date: @collection_date, collection_time: @collection_time })
        # "It will be ready for you to <span class='r---weight-600'>#{if @order?.provider.toUpperCase() == 'CASH' then 'pay and ' else '' }collect</span> at <span class='r---weight-600'>#{@supplier_name}</span> on <span class='r---weight-600'>#{@collection_date} after #{@collection_time}</span>"

    payment_text: ->
      return @$t('checkout_outro_eft') if @has_provider('offline_eft')
      ''

    shop2shop_offline_payment: ->
      @has_provider('shop2shop') && @order?.status == 'pending_payment' && @order?.requires_shipping && @order?.shipping_status == null

    formatted_total: ->
      number_to_currency({ symbol: @$store.getters['application/currency'], cents: @$store.state.orders.current?.total_in_cents })

  },

  methods: {
    dismiss: ->
      @$store.commit('checkout/show_outro', false)

    has_provider: (provider) ->
      @$store.getters['orders/has_provider'](provider)
  }
}
