/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {SignUpPayloadProfile} from './SignUpPayloadProfile';

/**
 * The SignUpPayload model module.
 * @module model/SignUpPayload
 * @version v1
 */
export class SignUpPayload {
  /**
   * Constructs a new <code>SignUpPayload</code>.
   * @alias module:model/SignUpPayload
   * @class
   * @param login {String} 
   * @param password {String} 
   */
  constructor(login, password) {
    this.login = login;
    this.password = password;
  }

  /**
   * Constructs a <code>SignUpPayload</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SignUpPayload} obj Optional instance to populate.
   * @return {module:model/SignUpPayload} The populated <code>SignUpPayload</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SignUpPayload();
      if (data.hasOwnProperty('login'))
        obj.login = ApiClient.convertToType(data['login'], 'String');
      if (data.hasOwnProperty('password'))
        obj.password = ApiClient.convertToType(data['password'], 'String');
      if (data.hasOwnProperty('first_name'))
        obj.first_name = ApiClient.convertToType(data['first_name'], 'String');
      if (data.hasOwnProperty('last_name'))
        obj.last_name = ApiClient.convertToType(data['last_name'], 'String');
      if (data.hasOwnProperty('profile'))
        obj.profile = SignUpPayloadProfile.constructFromObject(data['profile']);
    }
    return obj;
  }
}

/**
 * @member {String} login
 */
SignUpPayload.prototype.login = undefined;

/**
 * @member {String} password
 */
SignUpPayload.prototype.password = undefined;

/**
 * @member {String} first_name
 */
SignUpPayload.prototype.first_name = undefined;

/**
 * @member {String} last_name
 */
SignUpPayload.prototype.last_name = undefined;

/**
 * @member {module:model/SignUpPayloadProfile} profile
 */
SignUpPayload.prototype.profile = undefined;

