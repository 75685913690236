/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The BannerAdsInner model module.
 * @module model/BannerAdsInner
 * @version v1
 */
export class BannerAdsInner {
  /**
   * Constructs a new <code>BannerAdsInner</code>.
   * @alias module:model/BannerAdsInner
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>BannerAdsInner</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/BannerAdsInner} obj Optional instance to populate.
   * @return {module:model/BannerAdsInner} The populated <code>BannerAdsInner</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new BannerAdsInner();
      if (data.hasOwnProperty('image_url'))
        obj.image_url = ApiClient.convertToType(data['image_url'], 'String');
      if (data.hasOwnProperty('target_id'))
        obj.target_id = ApiClient.convertToType(data['target_id'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} image_url
 */
BannerAdsInner.prototype.image_url = undefined;

/**
 * @member {String} target_id
 */
BannerAdsInner.prototype.target_id = undefined;

