import _ from 'underscore'

export default class FilterEngine

  constructor: (config) ->
    @controller = config['controller']
    @element = config['element']
    @type = config['filter_type']
    @successor = config['successor']
    @pool = null
    @sellable = config['sellable']
    @selected = if @sellable? then @sellable["#{@type}_id"] else null

  # defaults: -> {
  #   controller: FilterManager,
  #   type: filter_type,
  #   successor: filter,
  #   pool: [sellables],
  #   selected_sellable: null
  # }

  set_successor: (filter) ->
    @successor = filter

  update: (sellables) ->
    @set_pool(sellables)
    options = @collect_options()
    if !!options.length
      preselected = @determine_preselected_option(options)
      @controller.filter_options_updated(@type, options)
      @select(preselected)
    else
      @controller.filter_options_updated(@type, [])
      @passthrough()

  collect_options: ->
    options = []
    options_ids = []
    for sellable in @pool when (sellable["#{@type}_id"]? && (sellable["#{@type}_id"] not in options_ids))
      option = @build_option(sellable)
      options.push(option)
      options_ids.push(option.id)
    @sorted(options)

  sorted: (options) -> _.sortBy(options, 'name')

  determine_preselected_option: (options) =>
    option = options.find((option) => option.id == @selected) || options[0]
    option.selected = true
    option

  build_option: (item) ->
    {
      id: item["#{@type}_id"],
      type: @type,
      name: item["#{@type}"]
    }

  set_pool: (pool) ->
    @pool = pool

  get_selection: ->
    @selected

  set_selection: (selection) ->
    @selected = selection

  select: (payload) ->
    filtered_pool = @filter(payload)
    filtered_pool.push @sellable if @sellable? and @sellable not in filtered_pool
    @sellable = null
    @set_selection(@selection_from_item(filtered_pool[0]))
    @select_handoff(filtered_pool)

  select_handoff: (sellables) ->
    if @successor
      @successor.update(sellables)
    else
      @controller.return_sellable(sellables[0])

  selection_from_item: (item) ->
    item["#{@type}_id"]

  passthrough: ->
    @successor.update(@pool) if @successor?

  filter: (payload) ->
    filtered_pool = []
    for item in @pool when item["#{@type}_id"] == payload['id']
      filtered_pool.push item
    filtered_pool
