/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The CartConfigPayment model module.
 * @module model/CartConfigPayment
 * @version v1
 */
export class CartConfigPayment {
  /**
   * Constructs a new <code>CartConfigPayment</code>.
   * @alias module:model/CartConfigPayment
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CartConfigPayment</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CartConfigPayment} obj Optional instance to populate.
   * @return {module:model/CartConfigPayment} The populated <code>CartConfigPayment</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CartConfigPayment();
      if (data.hasOwnProperty('options'))
        obj.options = ApiClient.convertToType(data['options'], ['String']);
      if (data.hasOwnProperty('gateway'))
        obj.gateway = ApiClient.convertToType(data['gateway'], Object);
    }
    return obj;
  }
}

/**
 * @member {Array.<String>} options
 */
CartConfigPayment.prototype.options = undefined;

/**
 * @member {Object} gateway
 */
CartConfigPayment.prototype.gateway = undefined;

