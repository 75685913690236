import _ from 'underscore'
import { api_for } from '@/services/helper'

state = {
  categories: [],
  error: null,
  loading: false
  
}

getters = {}

actions = {
  fetch: ({ commit, rootGetters, rootState }) ->
    commit('loading', true)
    commit('error', null)
    commit('clear')
    api = api_for(rootState.application, rootGetters['suppliers/current'])
    api.fetchRootCategories({ supplier_id: rootGetters['suppliers/current']?.id })
    .then (categories) ->
      commit('loading', false)
      commit('set', categories)
    .catch (response) ->
      commit('loading', false)
      commit('error', response)
      console.error 'root_categories.fetch.catch error:', response
}

mutations = {
  loading: (state, value) ->
    state.loading = value

  clear: (state) ->
    state.categories.length = 0

  error: (state, error) ->
    state.error = error

  set: (state, categories) ->
    categories_with_navigation_targets = _.filter(categories, (c) -> c.target = 'category'; c)
    state.categories = categories_with_navigation_targets
}

export default {
  namespaced: true
  state
  getters
  mutations
  actions
}
