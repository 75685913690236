MarketApiV1 = require('@/services/market_api_v1')
market_api = new MarketApiV1.DefaultApi()
import { api_for } from '../../services/helper'

state = {
  all: []
  current_id: null
  loading: true
}

getters = {
  get_suppliers: (state) -> (id) ->
    state.all.find((agency) -> agency.id == id)?.suppliers || null
}

actions = {
  fetch: ({ commit, dispatch, rootState }) ->
    commit('loading')
    api_for(rootState.application).fetchAgencies()
    .then (list) ->
      commit('set_all', list)
    .catch (error) ->
      dispatch('layout/offline_alert', null, { root: true })
    .finally ->
      commit('loaded')
}

mutations = {
  loading: (state) ->
    state.loading = true

  loaded: (state) ->
    state.loading = false

  set_all: (state, list) ->
    state.all = list

  set_current_id: (state, id) ->
    state.current_id = id
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
