class Sendinblue

  constructor: ({ @config }) ->
    console.log 'Sendinblue.tracking:', @config.sendinblue, @tracking_enabled()

  cart_updated: (contact_id) ->
    return unless @tracking_enabled() && @_looks_like_email(contact_id)

    window.sib.track 'cart_updated',
      {
        email: contact_id
      },
      {}

  cart_completed: (total, contact_id) ->
    return unless @tracking_enabled() && @_looks_like_email(contact_id)

    window.sib.track 'order_completed',
      {
        email: contact_id
        revenue: total
      },
      {
        revenue: total
      }

  cart_deleted: (contact_id) ->
    return unless @tracking_enabled() && @_looks_like_email(contact_id)

    window.sib.track 'cart_deleted',
      {
        email: contact_id
      },
      {}

  tracking_enabled: ->
    @config.enabled and @config.env in ['test', 'development', 'production']

  _looks_like_email: (email) ->
    new RegExp(/\S+@\S+\.\S+/).test(email)

export default new Sendinblue({ config: { env: process.env.NODE_ENV, enabled: !!process.env.VUE_APP_SENDINBLUE_ID } })
