import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ApplicationConfig from '@/application.yml'
import en from '@/plugins/i18n/en.yml'
import af from '@/plugins/i18n/af.yml'
import fr from '@/plugins/i18n/fr.yml'

Vue.use(VueI18n)

translations = {
  en: en,
  af: af,
  fr: fr
}

export default new VueI18n({
  locale: ApplicationConfig.meta.locale,
  fallbackLocale: 'en',
  messages: translations
})
