import FilterEngine from '../base_filter/filter_engine'

export default class RelationFilterEngine extends FilterEngine

  constructor: (config) ->
    super(config)
    @product = config.product
    @type = config.relation_name
    if @sellable?
      correlation_ids = @sellable.correlations.map (c) -> c.id
      for id in correlation_ids
        @selected = @correlation(id).name if @correlation(id)?

  correlations: ->
    correlations = {}
    for relation in @product.relations when relation['name'] == @type
      for correlation in relation['correlations']
        correlations[correlation['name']] = [] unless correlations[correlation['name']]?
        correlations[correlation['name']].push(correlation['id'])
    correlations

  correlation: (id) ->
    for name, ids of @correlations()
      return {name: name, ids: ids} if id in ids

  selection_from_item: (item) ->
    for name, ids of @correlations()
      for correlation in item['correlations']
        return name if correlation.id in ids

  filter: (payload) ->
    filtered_pool = []
    for item in @pool
      for correlation_id in item.correlations.map((c) -> c.id) when correlation_id in @correlations()[payload['name']]
        filtered_pool.push item
    filtered_pool

  update: (pool) ->
    @set_pool(pool)
    options = @collect_options()
    if !!options.length
      preselected = @determine_preselected_option(options)
      @controller.relation_filter_options_updated(@type, options)
      @select(preselected)
    else
      @controller.relation_filter_options_updated(@type, [])
      @passthrough()

  determine_preselected_option: (options) =>
    option = options.find((option) => option.name == @selected) || options[0]
    option.selected = true
    option

  collect_options: ->
    options = []
    options_names = []
    for item in @pool
      for sellable_correlation in item['correlations']
        correlation = @correlation(sellable_correlation.id)
        if correlation?
          option = {
            id: sellable_correlation.id,
            type: @type,
            name: correlation['name']
          }
          unless option['name'] in options_names
            options.push(option)
            options_names.push(option['name'])
    @sorted(options)
