/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {SellableJson} from './SellableJson';
import {Supplier} from './Supplier';

/**
 * The Offer model module.
 * @module model/Offer
 * @version v1
 */
export class Offer {
  /**
   * Constructs a new <code>Offer</code>.
   * @alias module:model/Offer
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>Offer</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Offer} obj Optional instance to populate.
   * @return {module:model/Offer} The populated <code>Offer</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Offer();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('end_at'))
        obj.end_at = ApiClient.convertToType(data['end_at'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('out_of_stock_at'))
        obj.out_of_stock_at = ApiClient.convertToType(data['out_of_stock_at'], 'String');
      if (data.hasOwnProperty('out_of_stock_streak'))
        obj.out_of_stock_streak = ApiClient.convertToType(data['out_of_stock_streak'], 'Number');
      if (data.hasOwnProperty('price'))
        obj.price = ApiClient.convertToType(data['price'], 'String');
      if (data.hasOwnProperty('price_in_cents'))
        obj.price_in_cents = ApiClient.convertToType(data['price_in_cents'], 'Number');
      if (data.hasOwnProperty('base_price_in_cents'))
        obj.base_price_in_cents = ApiClient.convertToType(data['base_price_in_cents'], 'Number');
      if (data.hasOwnProperty('price_updated_at'))
        obj.price_updated_at = ApiClient.convertToType(data['price_updated_at'], 'String');
      if (data.hasOwnProperty('promo'))
        obj.promo = ApiClient.convertToType(data['promo'], 'Boolean');
      if (data.hasOwnProperty('per_kg'))
        obj.per_kg = ApiClient.convertToType(data['per_kg'], 'Boolean');
      if (data.hasOwnProperty('per_kg_symbol'))
        obj.per_kg_symbol = ApiClient.convertToType(data['per_kg_symbol'], 'String');
      if (data.hasOwnProperty('per_kg_conversion_rate'))
        obj.per_kg_conversion_rate = ApiClient.convertToType(data['per_kg_conversion_rate'], 'String');
      if (data.hasOwnProperty('per_kg_qty_min'))
        obj.per_kg_qty_min = ApiClient.convertToType(data['per_kg_qty_min'], 'String');
      if (data.hasOwnProperty('per_kg_qty_step'))
        obj.per_kg_qty_step = ApiClient.convertToType(data['per_kg_qty_step'], 'String');
      if (data.hasOwnProperty('root_category_name'))
        obj.root_category_name = ApiClient.convertToType(data['root_category_name'], 'String');
      if (data.hasOwnProperty('sellable'))
        obj.sellable = SellableJson.constructFromObject(data['sellable']);
      if (data.hasOwnProperty('start_at'))
        obj.start_at = ApiClient.convertToType(data['start_at'], 'String');
      if (data.hasOwnProperty('stokvel'))
        obj.stokvel = ApiClient.convertToType(data['stokvel'], 'Boolean');
      if (data.hasOwnProperty('supplier'))
        obj.supplier = Supplier.constructFromObject(data['supplier']);
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
Offer.prototype.id = undefined;

/**
 * @member {String} end_at
 */
Offer.prototype.end_at = undefined;

/**
 * @member {String} name
 */
Offer.prototype.name = undefined;

/**
 * @member {String} out_of_stock_at
 */
Offer.prototype.out_of_stock_at = undefined;

/**
 * @member {Number} out_of_stock_streak
 */
Offer.prototype.out_of_stock_streak = undefined;

/**
 * @member {String} price
 */
Offer.prototype.price = undefined;

/**
 * @member {Number} price_in_cents
 */
Offer.prototype.price_in_cents = undefined;

/**
 * @member {Number} base_price_in_cents
 */
Offer.prototype.base_price_in_cents = undefined;

/**
 * @member {String} price_updated_at
 */
Offer.prototype.price_updated_at = undefined;

/**
 * @member {Boolean} promo
 */
Offer.prototype.promo = undefined;

/**
 * @member {Boolean} per_kg
 */
Offer.prototype.per_kg = undefined;

/**
 * @member {String} per_kg_symbol
 */
Offer.prototype.per_kg_symbol = undefined;

/**
 * @member {String} per_kg_conversion_rate
 */
Offer.prototype.per_kg_conversion_rate = undefined;

/**
 * @member {String} per_kg_qty_min
 */
Offer.prototype.per_kg_qty_min = undefined;

/**
 * @member {String} per_kg_qty_step
 */
Offer.prototype.per_kg_qty_step = undefined;

/**
 * @member {String} root_category_name
 */
Offer.prototype.root_category_name = undefined;

/**
 * @member {module:model/SellableJson} sellable
 */
Offer.prototype.sellable = undefined;

/**
 * @member {String} start_at
 */
Offer.prototype.start_at = undefined;

/**
 * @member {Boolean} stokvel
 */
Offer.prototype.stokvel = undefined;

/**
 * @member {module:model/Supplier} supplier
 */
Offer.prototype.supplier = undefined;

