import FilterEngine from '../base_filter/filter_engine'

export default class UnitFilterEngine extends FilterEngine

  constructor: (payload) ->
    super(payload)

  build_option: (item) ->
    {
      id: item["#{@type}_id"],
      type: @type,
      name: @build_input_name({ item: item }),
      unit: item.unit,
      measurement: item.measurement.toLowerCase() if item.measurement?
    }

  build_input_name: (payload) ->
    item = payload['item']
    if item['measurement']?
      "#{item[@type]} #{item['measurement'].toLowerCase()}"
    else
      "#{item[@type]}"

  sorted: (units) ->
    weights = {m: 0, l: 1, g: 1, k: 2}
    unit.measurement = 'unit' for unit in units when not unit.measurement
    units.sort (a, b) ->
      parseInt(a.unit) - parseInt(b.unit)
    units.sort (a, b) ->
      parseInt(weights[a.measurement[0]]) - parseInt(weights[b.measurement[0]])
    units
