<template>
  <v-dialog v-model="visible" persistent width="500">
    <v-card class="r---modal">
      <div class="d-flex align-center mb-4">
        <div class='r---modal-title'>{{ order_reference }}</div>

        <v-btn @click='dismiss' class='r---modal-close ml-auto' style='z-index: 1;'></v-btn>
      </div>
      <div class='text-center my-4'>
        <div class="r---circle r---circle-red mx-auto mb-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-lg" viewBox="0 0 16 16">
            <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0L7.005 3.1ZM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"/>
          </svg>
        </div>

        {{ $t('modal_order_timeout_text_line') }}
      </div>
      <div class="my-2"></div>

      <v-btn data-cy-reorder-button @click='initiate_reorder' :loading='loading_reorder' class='r---button r---button-primary' block>
        {{ $t('order_page_reorder_button') }}
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    loading_reorder: false
  }),

  computed: {
    visible() { return this.$store.state.layout.order_timeout_modal },
    order_id() { return this.$store.getters['orders/current_id']},
    order_reference() { return this.$store.state.orders.current && this.$store.state.orders.current.reference }
  },

  methods: {
    initiate_reorder() {
      this.loading_reorder = true
      this.$store.dispatch('orders/reorder')
      .then(() => {
        this.$store.commit('layout/order_timeout_modal', false)
        this.$router.push('/basket')
      })
      .catch((error) => {
        this.loading_reorder = false
        console.error(error)
      })
    },

    dismiss() {
      this.$store.commit('layout/order_timeout_modal', false)
    }
  }

}
</script>
