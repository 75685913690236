/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {ManagerApiV1ImportsCreateSuccessResponseImport} from './ManagerApiV1ImportsCreateSuccessResponseImport';

/**
 * The ManagerApiV1ImportsCreateSuccessResponse model module.
 * @module model/ManagerApiV1ImportsCreateSuccessResponse
 * @version v1
 */
export class ManagerApiV1ImportsCreateSuccessResponse {
  /**
   * Constructs a new <code>ManagerApiV1ImportsCreateSuccessResponse</code>.
   * @alias module:model/ManagerApiV1ImportsCreateSuccessResponse
   * @class
   * @param status {String} 
   * @param _import {module:model/ManagerApiV1ImportsCreateSuccessResponseImport} 
   */
  constructor(status, _import) {
    this.status = status;
    this._import = _import;
  }

  /**
   * Constructs a <code>ManagerApiV1ImportsCreateSuccessResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ManagerApiV1ImportsCreateSuccessResponse} obj Optional instance to populate.
   * @return {module:model/ManagerApiV1ImportsCreateSuccessResponse} The populated <code>ManagerApiV1ImportsCreateSuccessResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ManagerApiV1ImportsCreateSuccessResponse();
      if (data.hasOwnProperty('status'))
        obj.status = ApiClient.convertToType(data['status'], 'String');
      if (data.hasOwnProperty('import'))
        obj._import = ManagerApiV1ImportsCreateSuccessResponseImport.constructFromObject(data['import']);
    }
    return obj;
  }
}

/**
 * @member {String} status
 */
ManagerApiV1ImportsCreateSuccessResponse.prototype.status = undefined;

/**
 * @member {module:model/ManagerApiV1ImportsCreateSuccessResponseImport} _import
 */
ManagerApiV1ImportsCreateSuccessResponse.prototype._import = undefined;

