import { api_for } from '@/services/helper'
import _ from 'underscore'

state = {
  loading: false
  error: ''
  id: ''
  image_url: ''
  name: ''
  categories: []
  products: []
  supplier: null
}

getters = {}

actions = {
  fetch: ({ commit, rootGetters, rootState }, params) ->
    commit 'loading', true
    api = api_for(rootState.application, rootGetters['suppliers/current'])
    api.fetchCategory params.id, { supplier_id: rootGetters['suppliers/current']?.id }
    .then (category) ->
      commit 'set', category
      commit 'loading', false
    .catch (response) ->
      commit 'error', 'not_found'
      commit 'loading', false
      console.error 'root_categories.fetch.catch error:', response

  bootstrap: ({ commit }, params) ->
    commit('bootstrap', params)

}

mutations = {
  loading: (state, value) ->
    state.loading = value

  set: (state, category) ->
    state.id = category.id
    state.name = category.name
    state.image_url = category.image_url
    state.categories = with_navigation_targets(category.categories, 'category')
    state.products = with_navigation_targets(category.products, 'product')
    state.error = ''

  bootstrap: (state, category) ->
    state.id = category.id
    state.name = category.name
    state.image_url = null
    state.categories = []
    state.products = []
    state.error = ''

  clear: (state) ->
    state.id = null
    state.name = null
    state.image_url = null
    state.categories = []
    state.products = []
    state.error = ''

  set_supplier: (state, supplier) ->
    state.supplier = supplier

  clear_supplier: (state) ->
    state.supplier = null

  error: (state, message) ->
    state.error = message
}

export default {
  namespaced: true
  state
  getters
  mutations
  actions
}

with_navigation_targets = (items, target) -> _.collect(items, (i) -> i.target = target; i)
