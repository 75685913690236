import sellable_filter from '../../lib/sellable_filter'
import tool from '../../lib/helpers'

state = () -> {
  sellables: [],
  brand: [],
  relations: [],
  unit: [],
  pack: [],
  result: null
}

getters = {
  relations_with_options: (state) ->
    state.relations.filter (relation) -> relation.options.length > 0
}

actions = {
  initialize_product: ({ commit }, product) ->
    commit 'reset_relation_filters'
    sellable_filter.initialize({
      product: product
      result: (sellable) ->
        commit('result', sellable)
      update_filter: (filter) ->
        commit('update_filter', filter)
      update_relation_filter: (filter) ->
        commit('update_relation_filter', filter)
      reset_relation_filters: ->
        commit('reset_relation_filters')
    })

  select_option: ({ commit }, option) ->
    commit 'select_option', option
    sellable_filter.select_option(option)

  select_relation_option: ({ commit }, option) ->
    commit 'select_relation_option', option
    sellable_filter.select_relation_option(option)

  flush: ({ commit }) ->
    commit 'reset_product'
    commit 'reset_relation_filters'
}

mutations = {
  reset_product: (state) ->
    state[k] = [] for k, v of state

  update_filter: (state, filter) ->
    state[filter.type] = filter.options

  update_relation_filter: (state, filter) ->
    position = state.relations.findIndex (f) -> f.type == filter.type
    if position >= 0
      state.relations.splice(position, 1, filter)
    else state.relations.push(filter)

  reset_relation_filters: (state) ->
    state.relations = []

  result: (state, sellable) ->
    state.result = sellable

  select_option: (state, new_option) ->
    options = state[new_option.type].map (option) -> delete(option.selected); option
    options.find((option) -> option.id == new_option.id).selected = true
    state[new_option.type] = options

  select_relation_option: (state, new_option) ->
    relation = state.relations.find((relation) -> relation.type == new_option.type)
    options = relation.options.map (option) -> delete(option.selected); option
    options.find((option) -> option.name == new_option.name).selected = true
    relation.options = options
}

export default {
  namespaced: true
  state
  actions
  mutations
  getters
}
