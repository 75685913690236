export default class Storage
  constructor: ({ @key }) ->

  read: ->
    try
      JSON.parse(localStorage.getItem(@key))
    catch
      {}

  write: (object) ->
    localStorage.setItem(@key, JSON.stringify(object))

  clear: () ->
    localStorage.removeItem(@key)
