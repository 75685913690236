
export default {
  computed: {
    supplier_name: ->
      @$store.getters['suppliers/current']?.name

    supplier_selected: ->
      @$store.state.suppliers.current_id
  }

  methods: {
    reset_supplier: ->
      @$store.dispatch('suppliers/select', null)
      @$router.push('/') if @$router.currentRoute.path != '/'

  }
}
