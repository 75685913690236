<template>
  <v-row class="fill-height ma-0" align="center" justify="center">
    <v-progress-circular page-loading-indicator indeterminate :color='color'></v-progress-circular>
  </v-row>
</template>

<script>
export default {
  props: {
    color: { default: '#d1d5db' }
  }
}
</script>

<style>

</style>
