/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ClientConfigShipping model module.
 * @module model/ClientConfigShipping
 * @version v1
 */
export class ClientConfigShipping {
  /**
   * Constructs a new <code>ClientConfigShipping</code>.
   * @alias module:model/ClientConfigShipping
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ClientConfigShipping</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ClientConfigShipping} obj Optional instance to populate.
   * @return {module:model/ClientConfigShipping} The populated <code>ClientConfigShipping</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ClientConfigShipping();
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('payment_options'))
        obj.payment_options = ApiClient.convertToType(data['payment_options'], ['String']);
    }
    return obj;
  }
}

/**
 * @member {String} name
 */
ClientConfigShipping.prototype.name = undefined;

/**
 * @member {Array.<String>} payment_options
 */
ClientConfigShipping.prototype.payment_options = undefined;

