import Vue from 'vue'
import App from './app.vue'
import router from './router'
import { store } from './store'
import vuetify from './plugins/vuetify'
import VueSnip from 'vue-snip'
import i18n from './plugins/i18n'
import './styles/main.scss'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

# ERROR REPORTING
if process.env.NODE_ENV in ['production']
  Bugsnag.start({
    apiKey: '254792624ac6fac1884118af97498226',
    plugins: [new BugsnagPluginVue()]
  })

  bugsnagVue = Bugsnag.getPlugin('vue')
  bugsnagVue.installVueErrorHandler(Vue)
#

Vue.config.productionTip = false

if process.env.NODE_ENV in ['development', 'test']
  store.commit('application/read_localstore')

Vue.use(VueSnip)

store.commit("account/restore")
store.commit("suppliers/restore")
store.commit("orders/restore")
store.dispatch("checkout/restore")
store.dispatch("cart/restore")

detect_moya = ->
  if window.location.href.includes('referer=moya')
    window.localStorage.setItem('moya-discover', true)

window.addEventListener 'load', ->

  detect_moya()

  app = new Vue({
    el: '#app'
    i18n,
    router,
    store,
    vuetify,
    render: (h) -> h(App)
  })

  # 'only available during E2E tests'
  window.app = app if Boolean(window.Cypress)
