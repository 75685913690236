/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The Leaflet model module.
 * @module model/Leaflet
 * @version v1
 */
export class Leaflet {
  /**
   * Constructs a new <code>Leaflet</code>.
   * @alias module:model/Leaflet
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>Leaflet</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Leaflet} obj Optional instance to populate.
   * @return {module:model/Leaflet} The populated <code>Leaflet</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Leaflet();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('url'))
        obj.url = ApiClient.convertToType(data['url'], 'String');
      if (data.hasOwnProperty('supplier_id'))
        obj.supplier_id = ApiClient.convertToType(data['supplier_id'], 'String');
      if (data.hasOwnProperty('start_on'))
        obj.start_on = ApiClient.convertToType(data['start_on'], 'String');
      if (data.hasOwnProperty('end_on'))
        obj.end_on = ApiClient.convertToType(data['end_on'], 'String');
      if (data.hasOwnProperty('long'))
        obj._long = ApiClient.convertToType(data['long'], 'String');
      if (data.hasOwnProperty('lat'))
        obj.lat = ApiClient.convertToType(data['lat'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
Leaflet.prototype.id = undefined;

/**
 * @member {String} name
 */
Leaflet.prototype.name = undefined;

/**
 * @member {String} url
 */
Leaflet.prototype.url = undefined;

/**
 * @member {String} supplier_id
 */
Leaflet.prototype.supplier_id = undefined;

/**
 * @member {String} start_on
 */
Leaflet.prototype.start_on = undefined;

/**
 * @member {String} end_on
 */
Leaflet.prototype.end_on = undefined;

/**
 * @member {String} _long
 */
Leaflet.prototype._long = undefined;

/**
 * @member {String} lat
 */
Leaflet.prototype.lat = undefined;

