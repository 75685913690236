/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CartComparisonDebugCheapestBasketItems} from './CartComparisonDebugCheapestBasketItems';

/**
 * The CartComparisonDebugCheapestBasket model module.
 * @module model/CartComparisonDebugCheapestBasket
 * @version v1
 */
export class CartComparisonDebugCheapestBasket {
  /**
   * Constructs a new <code>CartComparisonDebugCheapestBasket</code>.
   * @alias module:model/CartComparisonDebugCheapestBasket
   * @class
   * @param items {Array.<module:model/CartComparisonDebugCheapestBasketItems>} 
   * @param total_in_cents {Number} 
   */
  constructor(items, total_in_cents) {
    this.items = items;
    this.total_in_cents = total_in_cents;
  }

  /**
   * Constructs a <code>CartComparisonDebugCheapestBasket</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CartComparisonDebugCheapestBasket} obj Optional instance to populate.
   * @return {module:model/CartComparisonDebugCheapestBasket} The populated <code>CartComparisonDebugCheapestBasket</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CartComparisonDebugCheapestBasket();
      if (data.hasOwnProperty('items'))
        obj.items = ApiClient.convertToType(data['items'], [CartComparisonDebugCheapestBasketItems]);
      if (data.hasOwnProperty('total_in_cents'))
        obj.total_in_cents = ApiClient.convertToType(data['total_in_cents'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Array.<module:model/CartComparisonDebugCheapestBasketItems>} items
 */
CartComparisonDebugCheapestBasket.prototype.items = undefined;

/**
 * @member {Number} total_in_cents
 */
CartComparisonDebugCheapestBasket.prototype.total_in_cents = undefined;

