import { api_for } from '@/services/helper'

state = {
  loading: true
  all: [
    {
      title: 'Goals specials',
      start_at: '25 May',
      end_at: '6 June'
    },
    {
      title: 'Giant specials',
      start_at: '25 May',
      end_at: '6 June'
    },
    {
      title: 'Jumbo specials',
      start_at: '25 May',
      end_at: '6 June'
    }
  ]
}

getters = {}

actions = {
  fetch: ({ commit, rootState }, location) ->
    commit('loading', true)
    location.radius = "#{location.radius}km"
    api_for(rootState.application).fetchLeaflets(location)
    .then (leaflets) ->
      commit('set', leaflets)
      commit('loading', false)

  fetch_supplier: ({ rootGetters, rootState }, supplier_id) ->
    api_for(rootState.application, rootGetters['suppliers/current'])
    .fetchSupplierLeaflets(supplier_id)
    .then (leaflets) -> leaflets
    .catch (response) ->
      console.error response
      []
}

mutations = {
  loading: (state, loading) ->
    state.loading = loading

  set: (state, leaflets) ->
    state.all = leaflets
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
