/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CartConfigPayment} from './CartConfigPayment';
import {CartConfigShipping} from './CartConfigShipping';

/**
 * The CartConfig model module.
 * @module model/CartConfig
 * @version v1
 */
export class CartConfig {
  /**
   * Constructs a new <code>CartConfig</code>.
   * @alias module:model/CartConfig
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CartConfig</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CartConfig} obj Optional instance to populate.
   * @return {module:model/CartConfig} The populated <code>CartConfig</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CartConfig();
      if (data.hasOwnProperty('shipping'))
        obj.shipping = CartConfigShipping.constructFromObject(data['shipping']);
      if (data.hasOwnProperty('payment'))
        obj.payment = CartConfigPayment.constructFromObject(data['payment']);
      if (data.hasOwnProperty('dispatch'))
        obj.dispatch = CartConfigShipping.constructFromObject(data['dispatch']);
    }
    return obj;
  }
}

/**
 * @member {module:model/CartConfigShipping} shipping
 */
CartConfig.prototype.shipping = undefined;

/**
 * @member {module:model/CartConfigPayment} payment
 */
CartConfig.prototype.payment = undefined;

/**
 * @member {module:model/CartConfigShipping} dispatch
 */
CartConfig.prototype.dispatch = undefined;

