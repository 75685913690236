/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CategoryCategories} from './CategoryCategories';

/**
 * The Products model module.
 * @module model/Products
 * @version v1
 */
export class Products extends Array {
  /**
   * Constructs a new <code>Products</code>.
   * @alias module:model/Products
   * @class
   * @extends Array
   */
  constructor() {
    super();
  }

  /**
   * Constructs a <code>Products</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Products} obj Optional instance to populate.
   * @return {module:model/Products} The populated <code>Products</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Products();
      ApiClient.constructFromObject(data, obj, 'CategoryCategories');
    }
    return obj;
  }
}
