import analytics from '@/services/google_analytics'
import { number_to_currency } from '@/lib/helpers'
import sendinblue from '@/services/sendinblue'

state = {
  loading: true
}

getters = {
  build_select_item_payload: (state, getters, rootState, rootGetters) -> (offer, qty=1) ->
    {
      currency: rootGetters['application/currency_iso']
      items: [{
        item_id: offer.sellable.barcode
        item_name: offer.sellable.name
        coupon: ''
        discount: 0
        affiliation: offer.supplier.name
        item_brand: offer.sellable.brand
        item_category: offer.sellable.product
        item_variant: ''
        price: number_to_currency({ cents: offer.price_in_cents })
        currency: rootGetters['application/currency_iso']
        quantity: qty
      }],
      value: number_to_currency({ cents: offer.price_in_cents * qty })
    }

  build_begin_checkout_payload: (state, getters, rootState, rootGetters) ->
    items = (getters['build_cart_item_payload'](item.offer, item.qty) for item in rootState.cart.items)
    {
      coupon: '',
      currency: rootGetters['application/currency_iso'],
      items: items,
      value: rootGetters['cart/total']
    }

  build_cart_item_payload: (state, getters, rootState, rootGetters) -> (offer, qty=1) ->
    {
      item_id: offer.sellable.barcode
      item_name: offer.sellable.name
      coupon: ''
      discount: 0
      affiliation: offer.supplier.name
      item_brand: offer.sellable.brand
      item_category: offer.sellable.product
      item_variant: ''
      price: number_to_currency({ cents: offer.price_in_cents * qty })
      currency: rootGetters['application/currency_iso']
      quantity: qty
    }

  build_purchase_payload: (state, getters, rootState, rootGetters) ->
    items = (getters['build_cart_item_payload'](item.offer, item.qty) for item in rootState.checkout.items)
    {
      affiliation: rootGetters['suppliers/current'].name,
      coupon: ''
      currency: rootGetters['application/currency_iso']
      items: items
      transaction_id: ''
      shipping: 0
      value: number_to_currency({ cents: rootGetters['checkout/total_in_cents'] })
      tax: 0
    }
}

actions = {
  select_item: ({}, event) ->
    analytics.track('select_item', event)

  add_to_cart: ({}, event) ->
    analytics.track('add_to_cart', event)

  begin_checkout: ({ getters }) ->
    analytics.track('begin_checkout', getters['build_begin_checkout_payload'])

  page_view: ->
    analytics.track('page_view', { page_title: document.title })

  purchase: ({ getters, dispatch, rootGetters }) ->
    analytics.track('purchase', getters['build_purchase_payload'])
    # dispatch('sendinblue_cart_completed')

  search: ({}, query) ->
    analytics.track('search', { search_term: query })

  sendinblue_cart_updated: ({ rootGetters }) ->
    sendinblue_id = rootGetters['account/sendinblue_id']
    sendinblue.cart_updated(sendinblue_id)

  sendinblue_cart_completed: ({ rootGetters }) ->
    sendinblue_id = rootGetters['account/sendinblue_id']
    sendinblue.cart_completed(sendinblue_id, number_to_currency({
      symbol: rootGetters['application/currency_iso']
      cents: rootGetters['checkout/total_in_cents']
    }))

  sendinblue_cart_deleted: ({ rootGetters }) ->
    sendinblue_id = rootGetters['account/sendinblue_id']
    sendinblue.cart_deleted(sendinblue_id)
}

mutations = {
  loading: (state, loading) ->
    state.loading = loading
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}