/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The SignUpPayloadProfile model module.
 * @module model/SignUpPayloadProfile
 * @version v1
 */
export class SignUpPayloadProfile {
  /**
   * Constructs a new <code>SignUpPayloadProfile</code>.
   * @alias module:model/SignUpPayloadProfile
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>SignUpPayloadProfile</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SignUpPayloadProfile} obj Optional instance to populate.
   * @return {module:model/SignUpPayloadProfile} The populated <code>SignUpPayloadProfile</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SignUpPayloadProfile();
      if (data.hasOwnProperty('business_name'))
        obj.business_name = ApiClient.convertToType(data['business_name'], 'String');
      if (data.hasOwnProperty('occupation'))
        obj.occupation = ApiClient.convertToType(data['occupation'], 'String');
      if (data.hasOwnProperty('language'))
        obj.language = ApiClient.convertToType(data['language'], 'String');
      if (data.hasOwnProperty('favoured_suppliers'))
        obj.favoured_suppliers = ApiClient.convertToType(data['favoured_suppliers'], ['String']);
      if (data.hasOwnProperty('terms'))
        obj.terms = ApiClient.convertToType(data['terms'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {String} business_name
 */
SignUpPayloadProfile.prototype.business_name = undefined;

/**
 * @member {String} occupation
 */
SignUpPayloadProfile.prototype.occupation = undefined;

/**
 * @member {String} language
 */
SignUpPayloadProfile.prototype.language = undefined;

/**
 * @member {Array.<String>} favoured_suppliers
 */
SignUpPayloadProfile.prototype.favoured_suppliers = undefined;

/**
 * @member {Boolean} terms
 */
SignUpPayloadProfile.prototype.terms = undefined;

