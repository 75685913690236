state = {
  open: false
  address: { lat: null, lon: null, formatted: null }
}

getters = {}

actions = {}

mutations = {
  loading: (state, loading) ->
    state.loading = loading

  open: (state, address) ->
    state.address.formatted = address
    state.open = true

  dismiss: (state) ->
    state.open = false
    state.address = { lat: null, lon: null, formatted: null }
}

export default {
  namespaced: true,
  state,
  mutations
}
