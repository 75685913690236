import FilterFactory from './filter_factory'

class FilterManager

  DEFAULT_FILTERS = ['brand', 'unit', 'pack']

  # paylod = {element, product, ?sellable}
  initialize: (@config) ->
    # @collection = config.product['sellables'].filter (sellable) ->
    #   if sellable.visible_for_suppliers then window.suppliers.supplier.id in sellable.visible_for_suppliers
    @sequence = []
    @start()

  start: ->
    configs = @build_filter_configs()
    successor = null
    for filter_config in configs.reverse()
      filter_config['successor'] = successor
      filter_factory = successor = FilterFactory.make(filter_config)
      @sequence.unshift(filter_factory)
    @sequence[0].update(@config.product.sellables)

  select_option: (option) ->
    filter = @sequence.find (filter) -> filter.type == option.type
    filter.select({ id: option.id })

  select_relation_option: (option) ->
    filter = @sequence.find (filter) -> filter.type == option.type
    filter.select({ name: option.name })

  build_filter_configs: ->
    configs = (@build_filter_config(type) for type in DEFAULT_FILTERS)

  build_filter_config: (type) ->
    {
      controller: this
      filter_type: type
      product: @config.product
      sellable: @config.sellable
    }
  
  build_relation_filter_configs: (relations) ->
    (@build_relation_filter_config(relation) for relation in relations)

  build_relation_filter_config: (relation) ->
    Object.assign @build_filter_config('relation'), {
      relation_name: relation.name
      relation_id: relation.id
    }

  filter_options_updated: (filter_type, options) ->
    @config.update_filter({
      type: filter_type,
      options: options
    })

  relation_filter_options_updated: (relation_name, options) ->
    @config.update_relation_filter({
      type: relation_name,
      options: options
    })

  rerender_offers: (payload) ->
    @sequence[@sequence.length - 1].ui.render_sellable(payload['sellable'])

  initialize_relation_filters: (relations, callback) ->
    @config.reset_relation_filters()
    configs = @build_relation_filter_configs(relations)
    selection = {}
    for filter in @sequence.filter((f) -> !(f.type in ['brand', 'unit', 'pack']))
      selection[filter.type] = filter.selected
    @sequence = @sequence.filter (f) -> f.type in ['brand', 'unit', 'pack']
    successor = @sequence[1]
    for filter_config in configs.reverse()
      filter_config['successor'] = successor
      filter = successor = FilterFactory.make(filter_config)
      filter.set_selection(selection[filter.type])
      @sequence.splice(1, 0, filter)
    @sequence[0].set_successor(@sequence[1])
    callback?()

  sort_relation_filters: (sellables, callback) =>
    correlations = sellables.reduce (collection, s) ->
      collection.concat(s.correlations)
    , []
    relations = []
    for relation in @config.product.relations
      position_total = correlations.filter((correlation) ->
        correlation.relation_id == relation.id
      ).reduce (total, correlation) ->
        total + Number(correlation.position)
      , 0
      relations.push Object.assign(relation, { position: position_total })
    relations.sort (a, b) ->
      x = a['position']; y = b['position']
      if x < y then -1 else if x > y then 1 else 0
    callback?(relations)

  return_sellable: (sellable) ->
    @config.result sellable

export default new FilterManager
