
export default {
  props: {
    shipping_status: { type: String, required: true }
  }

  methods: {
    order_status_pill: (shipping_status) ->
      classname = 'r---pill r---pill--'
      classname += 'green' if shipping_status in ['collected', 'delivered']
      classname += 'red' if shipping_status in ['stock_short', 'ready_for_collection', 'pending_payment']
      classname += 'yellow' if shipping_status in ['pending', 'preparing', 'ready_for_delivery', 'out_for_delivery']
      classname  }
}
