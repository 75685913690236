/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ManagerApiV1LeafletsCreateSuccessResponse model module.
 * @module model/ManagerApiV1LeafletsCreateSuccessResponse
 * @version v1
 */
export class ManagerApiV1LeafletsCreateSuccessResponse {
  /**
   * Constructs a new <code>ManagerApiV1LeafletsCreateSuccessResponse</code>.
   * @alias module:model/ManagerApiV1LeafletsCreateSuccessResponse
   * @class
   * @param id {String} 
   */
  constructor(id) {
    this.id = id;
  }

  /**
   * Constructs a <code>ManagerApiV1LeafletsCreateSuccessResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ManagerApiV1LeafletsCreateSuccessResponse} obj Optional instance to populate.
   * @return {module:model/ManagerApiV1LeafletsCreateSuccessResponse} The populated <code>ManagerApiV1LeafletsCreateSuccessResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ManagerApiV1LeafletsCreateSuccessResponse();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('path'))
        obj.path = ApiClient.convertToType(data['path'], 'String');
      if (data.hasOwnProperty('start_at'))
        obj.start_at = ApiClient.convertToType(data['start_at'], 'String');
      if (data.hasOwnProperty('end_at'))
        obj.end_at = ApiClient.convertToType(data['end_at'], 'String');
      if (data.hasOwnProperty('visible_at'))
        obj.visible_at = ApiClient.convertToType(data['visible_at'], 'String');
      if (data.hasOwnProperty('published'))
        obj.published = ApiClient.convertToType(data['published'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
ManagerApiV1LeafletsCreateSuccessResponse.prototype.id = undefined;

/**
 * @member {String} name
 */
ManagerApiV1LeafletsCreateSuccessResponse.prototype.name = undefined;

/**
 * @member {String} path
 */
ManagerApiV1LeafletsCreateSuccessResponse.prototype.path = undefined;

/**
 * @member {String} start_at
 */
ManagerApiV1LeafletsCreateSuccessResponse.prototype.start_at = undefined;

/**
 * @member {String} end_at
 */
ManagerApiV1LeafletsCreateSuccessResponse.prototype.end_at = undefined;

/**
 * @member {String} visible_at
 */
ManagerApiV1LeafletsCreateSuccessResponse.prototype.visible_at = undefined;

/**
 * @member {Boolean} published
 */
ManagerApiV1LeafletsCreateSuccessResponse.prototype.published = undefined;

