import { api_for } from '@/services/helper'

state = {
  id: null
  loading: false
  name: ''
  brands: []
  relations: []
  units: []
  packs: []
  sellables: []
  supplier: null
}

getters = {}

actions = {
  fetch: ({ commit, rootState, rootGetters }, params) ->
    commit 'loading', true
    commit 'set_error', ''
    api = api_for(rootState.application, rootGetters['suppliers/current'])
    api.fetchProduct params.id, { supplier_id: rootGetters['suppliers/current']?.id }
    .then (product) ->
      commit 'set', product
    .catch (response) ->
      commit 'set_error', response.status
    .finally ->
      commit 'loading', false

  fetch_offers: ({ commit, rootState, rootGetters }, params) ->
    api = api_for(rootState.application, rootGetters['suppliers/current'])
    api.fetchProductOffers params.id, { supplier_id: rootGetters['suppliers/current']?.id }
    .then (product) -> product
    .catch (response) ->
      console.error('root_products.fetch.catch error:', response)
      []

  fetch_root_products: ({ commit, rootState, rootGetters }, params) ->
    api = api_for(rootState.application, rootGetters['suppliers/current'])
    api.fetchProduct '', { supplier_id: rootGetters['suppliers/current']?.id }
    .then (products) -> products
    .catch (response) ->
      console.error('root_products.fetch_root_products.catch error:', response)
      []
    .finally ->
      commit 'loading', false

  bootstrap: ({ commit }, params) ->
    commit('bootstrap', params)
}

mutations = {
  loading: (state, value) -> state.loading = value

  set: (state, product) ->
    state.id = product.id
    state.name = product.name
    state.brands = product.brands
    state.relations = product.relations
    state.units = product.units
    state.packs = product.packs
    state.sellables = product.sellables

  bootstrap: (state, product) ->
    state.id = product.id
    state.name = product.name

  set_supplier: (state, supplier) ->
    state.supplier = supplier

  clear_supplier: (state) ->
    state.supplier = null

  set_error: (state, message) -> state.error = message
}

export default {
  namespaced: true
  state
  getters
  mutations
  actions
}
