/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The OrderPrepareJob model module.
 * @module model/OrderPrepareJob
 * @version v1
 */
export class OrderPrepareJob {
  /**
   * Constructs a new <code>OrderPrepareJob</code>.
   * @alias module:model/OrderPrepareJob
   * @class
   * @param order_id {String} 
   * @param order_reference {String} 
   * @param order_items {Array.<Object>} 
   * @param agent_name {String} 
   * @param requires_bags {Boolean} 
   * @param bag_price_in_cents {Number} 
   * @param order_subtotal_in_cents {Number} 
   * @param order_total_in_cents {Number} 
   * @param requires_shipping {Boolean} 
   * @param provider {String} 
   * @param debtor_card_number {String} 
   * @param data {Object} 
   */
  constructor(order_id, order_reference, order_items, agent_name, requires_bags, bag_price_in_cents, order_subtotal_in_cents, order_total_in_cents, requires_shipping, provider, debtor_card_number, data) {
    this.order_id = order_id;
    this.order_reference = order_reference;
    this.order_items = order_items;
    this.agent_name = agent_name;
    this.requires_bags = requires_bags;
    this.bag_price_in_cents = bag_price_in_cents;
    this.order_subtotal_in_cents = order_subtotal_in_cents;
    this.order_total_in_cents = order_total_in_cents;
    this.requires_shipping = requires_shipping;
    this.provider = provider;
    this.debtor_card_number = debtor_card_number;
    this.data = data;
  }

  /**
   * Constructs a <code>OrderPrepareJob</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/OrderPrepareJob} obj Optional instance to populate.
   * @return {module:model/OrderPrepareJob} The populated <code>OrderPrepareJob</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new OrderPrepareJob();
      if (data.hasOwnProperty('order_id'))
        obj.order_id = ApiClient.convertToType(data['order_id'], 'String');
      if (data.hasOwnProperty('order_reference'))
        obj.order_reference = ApiClient.convertToType(data['order_reference'], 'String');
      if (data.hasOwnProperty('order_items'))
        obj.order_items = ApiClient.convertToType(data['order_items'], [Object]);
      if (data.hasOwnProperty('agent_name'))
        obj.agent_name = ApiClient.convertToType(data['agent_name'], 'String');
      if (data.hasOwnProperty('requires_bags'))
        obj.requires_bags = ApiClient.convertToType(data['requires_bags'], 'Boolean');
      if (data.hasOwnProperty('bag_price_in_cents'))
        obj.bag_price_in_cents = ApiClient.convertToType(data['bag_price_in_cents'], 'Number');
      if (data.hasOwnProperty('order_subtotal_in_cents'))
        obj.order_subtotal_in_cents = ApiClient.convertToType(data['order_subtotal_in_cents'], 'Number');
      if (data.hasOwnProperty('order_total_in_cents'))
        obj.order_total_in_cents = ApiClient.convertToType(data['order_total_in_cents'], 'Number');
      if (data.hasOwnProperty('requires_shipping'))
        obj.requires_shipping = ApiClient.convertToType(data['requires_shipping'], 'Boolean');
      if (data.hasOwnProperty('provider'))
        obj.provider = ApiClient.convertToType(data['provider'], 'String');
      if (data.hasOwnProperty('debtor_card_number'))
        obj.debtor_card_number = ApiClient.convertToType(data['debtor_card_number'], 'String');
      if (data.hasOwnProperty('data'))
        obj.data = ApiClient.convertToType(data['data'], Object);
    }
    return obj;
  }
}

/**
 * @member {String} order_id
 */
OrderPrepareJob.prototype.order_id = undefined;

/**
 * @member {String} order_reference
 */
OrderPrepareJob.prototype.order_reference = undefined;

/**
 * @member {Array.<Object>} order_items
 */
OrderPrepareJob.prototype.order_items = undefined;

/**
 * @member {String} agent_name
 */
OrderPrepareJob.prototype.agent_name = undefined;

/**
 * @member {Boolean} requires_bags
 */
OrderPrepareJob.prototype.requires_bags = undefined;

/**
 * @member {Number} bag_price_in_cents
 */
OrderPrepareJob.prototype.bag_price_in_cents = undefined;

/**
 * @member {Number} order_subtotal_in_cents
 */
OrderPrepareJob.prototype.order_subtotal_in_cents = undefined;

/**
 * @member {Number} order_total_in_cents
 */
OrderPrepareJob.prototype.order_total_in_cents = undefined;

/**
 * @member {Boolean} requires_shipping
 */
OrderPrepareJob.prototype.requires_shipping = undefined;

/**
 * @member {String} provider
 */
OrderPrepareJob.prototype.provider = undefined;

/**
 * @member {String} debtor_card_number
 */
OrderPrepareJob.prototype.debtor_card_number = undefined;

/**
 * @member {Object} data
 */
OrderPrepareJob.prototype.data = undefined;

