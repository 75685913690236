/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {Supplier} from './Supplier';

/**
 * The CartComparisonInner model module.
 * @module model/CartComparisonInner
 * @version v1
 */
export class CartComparisonInner {
  /**
   * Constructs a new <code>CartComparisonInner</code>.
   * @alias module:model/CartComparisonInner
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CartComparisonInner</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CartComparisonInner} obj Optional instance to populate.
   * @return {module:model/CartComparisonInner} The populated <code>CartComparisonInner</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CartComparisonInner();
      if (data.hasOwnProperty('supplier'))
        obj.supplier = Supplier.constructFromObject(data['supplier']);
      if (data.hasOwnProperty('items'))
        obj.items = ApiClient.convertToType(data['items'], [Object]);
      if (data.hasOwnProperty('subtotal_in_cents'))
        obj.subtotal_in_cents = ApiClient.convertToType(data['subtotal_in_cents'], 'Number');
      if (data.hasOwnProperty('total_in_cents'))
        obj.total_in_cents = ApiClient.convertToType(data['total_in_cents'], 'Number');
      if (data.hasOwnProperty('shipping_cost_in_cents'))
        obj.shipping_cost_in_cents = ApiClient.convertToType(data['shipping_cost_in_cents'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {module:model/Supplier} supplier
 */
CartComparisonInner.prototype.supplier = undefined;

/**
 * @member {Array.<Object>} items
 */
CartComparisonInner.prototype.items = undefined;

/**
 * @member {Number} subtotal_in_cents
 */
CartComparisonInner.prototype.subtotal_in_cents = undefined;

/**
 * @member {Number} total_in_cents
 */
CartComparisonInner.prototype.total_in_cents = undefined;

/**
 * @member {Number} shipping_cost_in_cents
 */
CartComparisonInner.prototype.shipping_cost_in_cents = undefined;

