/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {Offer} from './Offer';

/**
 * The CartItem model module.
 * @module model/CartItem
 * @version v1
 */
export class CartItem {
  /**
   * Constructs a new <code>CartItem</code>.
   * @alias module:model/CartItem
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CartItem</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CartItem} obj Optional instance to populate.
   * @return {module:model/CartItem} The populated <code>CartItem</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CartItem();
      if (data.hasOwnProperty('qty'))
        obj.qty = ApiClient.convertToType(data['qty'], 'String');
      if (data.hasOwnProperty('offer'))
        obj.offer = Offer.constructFromObject(data['offer']);
    }
    return obj;
  }
}

/**
 * @member {String} qty
 */
CartItem.prototype.qty = undefined;

/**
 * @member {module:model/Offer} offer
 */
CartItem.prototype.offer = undefined;

