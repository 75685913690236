/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The V1LeafletsBody model module.
 * @module model/V1LeafletsBody
 * @version v1
 */
export class V1LeafletsBody {
  /**
   * Constructs a new <code>V1LeafletsBody</code>.
   * @alias module:model/V1LeafletsBody
   * @class
   * @param name {String} 
   * @param path {String} 
   * @param start_at {String} 
   * @param end_at {String} 
   * @param visible_at {String} 
   * @param published {Boolean} 
   */
  constructor(name, path, start_at, end_at, visible_at, published) {
    this.name = name;
    this.path = path;
    this.start_at = start_at;
    this.end_at = end_at;
    this.visible_at = visible_at;
    this.published = published;
  }

  /**
   * Constructs a <code>V1LeafletsBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/V1LeafletsBody} obj Optional instance to populate.
   * @return {module:model/V1LeafletsBody} The populated <code>V1LeafletsBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new V1LeafletsBody();
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('path'))
        obj.path = ApiClient.convertToType(data['path'], 'String');
      if (data.hasOwnProperty('supplier_ids'))
        obj.supplier_ids = ApiClient.convertToType(data['supplier_ids'], 'Number');
      if (data.hasOwnProperty('start_at'))
        obj.start_at = ApiClient.convertToType(data['start_at'], 'String');
      if (data.hasOwnProperty('end_at'))
        obj.end_at = ApiClient.convertToType(data['end_at'], 'String');
      if (data.hasOwnProperty('visible_at'))
        obj.visible_at = ApiClient.convertToType(data['visible_at'], 'String');
      if (data.hasOwnProperty('published'))
        obj.published = ApiClient.convertToType(data['published'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {String} name
 */
V1LeafletsBody.prototype.name = undefined;

/**
 * @member {String} path
 */
V1LeafletsBody.prototype.path = undefined;

/**
 * @member {Number} supplier_ids
 */
V1LeafletsBody.prototype.supplier_ids = undefined;

/**
 * @member {String} start_at
 */
V1LeafletsBody.prototype.start_at = undefined;

/**
 * @member {String} end_at
 */
V1LeafletsBody.prototype.end_at = undefined;

/**
 * @member {String} visible_at
 */
V1LeafletsBody.prototype.visible_at = undefined;

/**
 * @member {Boolean} published
 */
V1LeafletsBody.prototype.published = undefined;

