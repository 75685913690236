MarketApiV1 = require('@/services/market_api_v1')
AgencyApiV1 = require('@/services/agency_api_v1')

market_api_client = new MarketApiV1.ApiClient()
market_api = new MarketApiV1.DefaultApi(market_api_client)
agency_api_client = new AgencyApiV1.ApiClient()
agency_api = new AgencyApiV1.DefaultApi(agency_api_client)

export api_for = (config, supplier) ->
  if config.features.agency
    agency_api.apiClient.basePath = config.api_host
    agency_api
  else
    if supplier and !!supplier.id
      agency_api.apiClient.basePath = supplier.api_endpoint
      agency_api
    else
      market_api.apiClient.basePath = config.api_host
      market_api
