/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CorrelationJson} from './CorrelationJson';

/**
 * The RelationJson model module.
 * @module model/RelationJson
 * @version v1
 */
export class RelationJson {
  /**
   * Constructs a new <code>RelationJson</code>.
   * @alias module:model/RelationJson
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RelationJson</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RelationJson} obj Optional instance to populate.
   * @return {module:model/RelationJson} The populated <code>RelationJson</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RelationJson();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('correlations'))
        obj.correlations = ApiClient.convertToType(data['correlations'], [CorrelationJson]);
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
RelationJson.prototype.id = undefined;

/**
 * @member {String} name
 */
RelationJson.prototype.name = undefined;

/**
 * @member {Array.<module:model/CorrelationJson>} correlations
 */
RelationJson.prototype.correlations = undefined;

