import { api_for } from '@/services/helper'

state = {
  loading: false
  offers: []
}

getters = {}

actions = {
  fetch: ({ commit, rootGetters, rootState }, params) ->
    commit('loading', true)
    api = api_for(rootState.application, rootGetters['suppliers/current'])
    api.fetchCategoryBanners( params.category_id, { supplier_id: params.supplier_id })
      .then (response) ->
        commit('loading', false)
        commit('set', response)
      .catch (error) ->
        commit('loading', false)
}

mutations = {
  loading: (state, value) ->
    state.loading = value

  set: (state, offers) ->
    state.offers = offers

  clear: (state) ->
    state.offers = []

  set_supplier: (state, supplier) ->
    state.supplier = supplier

  clear_supplier: (state) ->
    state.supplier = null
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
