/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The User model module.
 * @module model/User
 * @version v1
 */
export class User {
  /**
   * Constructs a new <code>User</code>.
   * @alias module:model/User
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>User</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/User} obj Optional instance to populate.
   * @return {module:model/User} The populated <code>User</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new User();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('login'))
        obj.login = ApiClient.convertToType(data['login'], 'String');
      if (data.hasOwnProperty('full_name'))
        obj.full_name = ApiClient.convertToType(data['full_name'], 'String');
      if (data.hasOwnProperty('first_name'))
        obj.first_name = ApiClient.convertToType(data['first_name'], 'String');
      if (data.hasOwnProperty('last_name'))
        obj.last_name = ApiClient.convertToType(data['last_name'], 'String');
      if (data.hasOwnProperty('profile'))
        obj.profile = ApiClient.convertToType(data['profile'], Object);
      if (data.hasOwnProperty('beta_tester'))
        obj.beta_tester = ApiClient.convertToType(data['beta_tester'], 'Boolean');
      if (data.hasOwnProperty('addresses'))
        obj.addresses = ApiClient.convertToType(data['addresses'], Object);
      if (data.hasOwnProperty('debtorcards'))
        obj.debtorcards = ApiClient.convertToType(data['debtorcards'], Array);
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
User.prototype.id = undefined;

/**
 * @member {String} login
 */
User.prototype.login = undefined;

/**
 * @member {String} full_name
 */
User.prototype.full_name = undefined;

/**
 * @member {String} first_name
 */
User.prototype.first_name = undefined;

/**
 * @member {String} last_name
 */
User.prototype.last_name = undefined;

/**
 * @member {Object} profile
 */
User.prototype.profile = undefined;

/**
 * @member {Boolean} beta_tester
 */
User.prototype.beta_tester = undefined;

/**
 * @member {Object} addresses
 */
User.prototype.addresses = undefined;

/**
 * @member {Array} debtorcards
 */
User.prototype.debtorcards = undefined;

