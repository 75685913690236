import FilterEngine from '../base_filter/filter_engine'

export default class PackFilterEngine extends FilterEngine

  constructor: (payload) ->
    super(payload)

  sorted: (options) ->
    options.sort (a, b) ->
      if parseInt(a.name) <= parseInt(b.name) then -1 else if parseInt(a.name) >= parseInt(b.name) then 1 else 0
    options
