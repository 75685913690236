
export default {
  props: {
    per_kg: { type: Boolean, default: false },
    per_kg_conversion_rate: { type: String, default: '1.0' },
    per_kg_qty_min: { type: String, default: '0.250'},
    per_kg_qty_step: { type: String, default: '0.050'},
    unit_label: { type: String, default: '' },
    value: { type: Number } # v-model
  }

  data: -> {
    user_facing_qty: 0
    minimum: if @per_kg then @per_kg_qty_min else 1
    increment: if @per_kg then @per_kg_qty_step else 1
    symbol: if @per_kg then @unit_label else ''
  }

  created: ->
    if @value == 0
      value = Number(@minimum)
      @user_facing_qty = if @per_kg then @formatted_per_kg_qty(value) else value
    else
      @user_facing_qty = if @per_kg then @formatted_per_kg_qty(@convert_kg_to_symbol(@value)) else Number(@value)

  watch: {
    user_facing_qty: (v) ->
      output_qty = if @per_kg then @convert_symbol_to_kg(v) else v
      @$emit('input', output_qty)
  }

  methods: {
    convert_kg_to_symbol: (value) ->
      Number(value) / Number(@per_kg_conversion_rate)

    convert_symbol_to_kg: (value) ->
      Number(value) * Number(@per_kg_conversion_rate)

    formatted_per_kg_qty: (value) ->
      if @unit_label.toLowerCase() in ['kg', 'l']
        value.toFixed(3) 
      else
        value

    decrease_qty: ->
      user_facing_qty = Number.parseFloat(@user_facing_qty)
      if user_facing_qty > Number.parseFloat(@minimum)
        next_value = user_facing_qty - Number.parseFloat(@increment)
        @user_facing_qty = if @per_kg then @formatted_per_kg_qty(next_value) else next_value

    increase_qty: ->
      value = Number.parseFloat(@user_facing_qty) + Number.parseFloat(@increment)
      @user_facing_qty = if @per_kg then @formatted_per_kg_qty(value) else value

    begin_increasing_qty: ->
      @increase_qty()
      @timeout = setTimeout =>
        @interval = setInterval(@increase_qty, 100)
      , 500

    end_increasing_qty: ->
      clearTimeout(@timeout)
      clearInterval(@interval)

    begin_decreasing_qty: ->
      @decrease_qty()
      @timeout = setTimeout =>
        @interval = setInterval(@decrease_qty, 100)
      , 500

    end_decreasing_qty: ->
      clearTimeout(@timeout)
      clearInterval(@interval)
  }
}
