import Vue from 'vue'
import Router from 'vue-router'
import { store } from '@/store'
import BootPage from '@/mixins/boot_item'

Vue.use(Router)

# class RouteMeta {
#   title: string;

#   constructor ({ title }: { title: string }) {
#     this.title = title;
#   }
# }

routes = [
  {
    path: '/',
    component: () => import(### webpackChunkName: 'home' ### "@/views/root_categories_page.vue"),
    name: "catalog_root",
    meta: {
      page_title: 'Catalog'
    }
  },
  {
    path: "/category/:id",
    name: "category",
    component: () => import(### webpackChunkName: "category" ### "@/views/category_page.vue")
    meta: {
      page_title: 'Category'
    }
  },
  {
    path: "/product/:id",
    name: "product",
    component: () => import(### webpackChunkName: 'product_page' ### '@/views/product_page.vue')
    meta: {
      page_title: 'Product'
    }
  },
  {
    path: "/product/:id/offers",
    name: "product_offers",
    component: () => import(### webpackChunkName: 'product_page' ### '@/views/routes/product_offers.vue')
    meta: {
      page_title: 'Product offers'
    }
  },
  {
    path: '/promotions',
    name: 'promotions',
    component: => import(### webpackChunkName: 'promotions' ### '@/views/promotions_page'),
    meta: {
      page_title: 'Promotions'
    }
  },
  {
    path: '/leaflets_promotions',
    name: 'leaflet_promotions',
    component: => import(### webpackChunkName: 'promotions' ### '@/views/routes/leaflet_promotions'),
    meta: {
      page_title: 'Promotions'
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(### webpackChunkName: 'search' ### "@/views/search_page.vue"),
    meta: {
      page_title: 'Search'
    }
  },
  {
    path: '/stokvel',
    component: () => import(### webpackChunkName: 'stokvel' ### "@/views/stokvel_page.vue"),
    meta: {
      page_title: 'Stokvel'
    }
  },
  {
    path: '/combos',
    name: 'combos',
    component: => import(### webpackChunkName: 'combos' ### "@/views/combos_page.vue"),
    meta: {
      page_title: 'Combos'
    }
  },
  {
    path: '/agencies',
    component: => import(### webpackChunkName: 'agencies' ### '@/views/agencies_page'),
  },
  {
    path: "/leaflets",
    name: "leaflets",
    component: () => import(### webpackChunkName: 'leaflets' ### "@/views/leaflets_page.vue"),
    meta: {
      page_title: 'Leaflets'
    }
  },
  {
    path: "/basket",
    name: "basket",
    component: () => import(### webpackChunkName: 'basket_page' ### "@/views/basket_page.vue"),
    meta: {
      page_title: 'Basket'
    }
  },
  {
    path: "/basket/compare",
    name: "basket_compare",
    component: () => import(### webpackChunkName: "basket_compare" ### "@/views/basket_compare_page.vue"),
    meta: {
      page_title: 'Best basket'
      authenticate: true
    }
  },
  {
    path: "/basket/compare_debug",
    name: "basket_compare_debug",
    component: () => import(### webpackChunkName: "basket_compare" ### "@/views/basket_compare_debug_page.vue"),
    meta: {
      page_title: 'Best basket debug'
      authenticate: true
    }
  },
  {
    path: '/checkout/dispatch',
    component: () => import(### webpackChunkName: "dispatch" ### '@/views/checkout/dispatch.vue'),
    meta: {
      page_title: 'Checkout dispatch'
      authenticate: true
    }
  },
  {
    path: '/checkout/shipping_address',
    component: () => import(### webpackChunkName: "shipping_address" ### '@/views/checkout/shipping_address.vue'),
    meta: {
      page_title: 'Checkout delivery address'
      authenticate: true
    }
  },
  {
    path: '/checkout/confirmation',
    component: () => import(### webpackChunkName: "confirmation" ### '@/views/checkout/confirmation.vue'),
    meta: {
      page_title: 'Checkout confirm'
      authenticate: true
    }
  },
  {
    path: '/checkout/payment',
    component: () => import(### webpackChunkName: "payment" ### '@/views/checkout/payment.vue'),
    meta: {
      page_title: 'Checkout payment'
      authenticate: true
    }
  },
  # {
  #   path: "/catalog",
  #   name: "catalog",
  #   component: () => import(### webpackChunkName: "catalog" ### "@/views/root_categories_page.vue")
  # },
  {
    path: "/sign_in",
    name: "login",
    component: () => import(### webpackChunkName: 'sign_in' ### "@/views/sign_in_page.vue"),
    meta: {
      page_title: 'Login'
    }
  },
  {
    path: "/register",
    name: "register",
    component: () => import(### webpackChunkName: 'sign_up' ### "@/views/register_page.vue"),
    meta: {
      page_title: 'Register'
    }
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import(### webpackChunkName: 'profile' ### "@/views/profile_page.vue"),
    meta: {
      page_title: 'Profile'
      authenticate: true
    }
  },
  {
    path: "/debtorcards",
    component: () => import(### webpackChunkName: 'debtorcards' ### "@/views/debtorcards_page.vue"),
    meta: {
      page_title: 'Debtorcards',
      authenticate: true
    }
  },
  {
    path: '/orders/:id',
    name: 'order show',
    component: () => import(### webpackChunkName: 'order_show' ### '@/views/order/show.vue'),
    meta: {
      page_title: 'Order'
      authenticate: true
    }
  },
  {
    path: '/orders/:id/pay',
    name: 'order payment',
    component: () => import(### webpackChunkName: 'order_payment' ### '@/views/order/payment.vue'),
    meta: {
      page_title: 'Order payment'
      authenticate: true
    }
  },
  {
    path: '/orders',
    name: 'orders index',
    component: () => import(### webpackChunkName: 'order_index' ### '@/views/order/index.vue'),
    meta: {
      page_title: 'Order history'
      authenticate: true
    }
  },
  {
    path: '/address_management',
    component: () => import(### webpackChunkName: 'addresses' ### '@/views/address_management_page.vue'),
    meta: {
      page_title: 'Delivery adresses'
      authenticate: true
    }
  },
  {
    path: '/terms_and_conditions',
    component: () => import(### webpackChunkName: 'terms_and_conditions ' ### '@/views/terms_and_conditions_page.vue'),
    meta: {
      page_title: 'Terms and conditions'
    }
  },
  {
    path: "*",
    component: () => import(### webpackChunkName: 'error_layout' ### "@/layouts/ErrorLayout.vue")
    children: [
      {
        path: "404",
        name: "error-404",
        component: () =>
          import("@/views/error/NotFoundPage.vue")
      },
      {
        path: "*",
        name: "error-not-found",
        component: () => import("@/views/error/NotFoundPage.vue")
      }
    ]
  }
]

routes.forEach (route) =>
  page = route.component
  if typeof page is 'function'
    route.component = () =>
      module = await page()
      return { extends: module.default || module, mixins: [BootPage] }
  if typeof page is 'object'
    route.component = { extends: page, mixins: [BootPage] }

router = new Router({
  mode: 'history',
  base: '/',
  routes: routes
})

checkout_path = new RegExp('^/checkout')
order_path = new RegExp('^/orders/')

router.beforeEach (to, from, next) =>
  if process.env.NODE_ENV is 'development'
    console.log('routing to: ', to.path, ' with query: ', to.query, ' from: ', from.path)

  if !!to.meta.authenticate && !store.getters['account/logged_in']
    return next("/sign_in?redirect_to=#{to.path}")

  if order_path.test(from.path) and checkout_path.test(to.path)
    return next('/orders')

  if checkout_path.test(to.path) and not store.getters['checkout/has_valid_config']
    message = if store.getters['application/is_agency'] then 'invalid config for agency' else 'invalid config for market'
    store.dispatch('layout/notify', message)
    return next('/basket')
  next()

router.afterEach (to, from) =>
  document.title = to.meta.page_title || store.getters['application/name']
  if to.name not in ['category', 'product']
    store.dispatch('analytics/page_view', to.meta.page_title) if to.meta.page_title

router.go_home = () ->
  router.push('/') unless router.currentRoute.path is '/'

export default router
