
import { number_to_currency } from '@/lib/helpers'
import SidePanel from '@/components/side_panel'

export default {
  components: { SidePanel }

  methods: {
    is_page: (routes) ->
      @$route.name == routes

    focus_search_input: ->
      input = document.querySelector('.r---searchinput input')
      document.querySelector('.r---searchinput input').focus() if input
  },

  computed: {
    editing_order: ->
      @$store.getters["cart/is_editing_order"]

    formatted_total: ->
      if @$store.state.cart.proxy_order_id then @formatted_order_total else @formatted_cart_total

    formatted_cart_total: ->
      number_to_currency({ symbol: @$store.getters['application/currency'], cents: @$store.getters["cart/subtotal_in_cents"]})

    formatted_order_total: ->
      number_to_currency({ symbol: @$store.getters['application/currency'], cents: @$store.state.orders.current?.subtotal_in_cents})

    cart_button_destination: ->
      if @$store.state.cart.proxy_order_id then "/orders/#{@$store.state.cart.proxy_order_id}" else '/basket'

    cart_item_count: ->
      return @$store.getters['orders/current_items']?.length if @$store.state.cart.proxy_order_id
      @$store.getters["cart/item_count"]

    alert: ->
      @$store.state.layout.alert

    sidepanel_open: {
      get: -> @$store.state.layout.sidepanel
      set: (v) -> @$store.commit('layout/sidepanel', v)
    }

    search_button_available: ->
      !!@$store.getters['suppliers/current']

  }
}
