import as_json from '../../lib/helpers'
ApiV1 = require('../../services/market_api_v1')
api = new ApiV1.DefaultApi()

state = {
  loading: true
  all: []
}

getters = {}

actions = {
  fetch: ({ commit, rootState }) ->
    api.fetchBannerAds()
      .then (ads) ->
        commit 'loading', false
        commit 'set', ads
      .catch (response) ->
        commit 'loading', false
        commit 'error', 'not_found'
}

mutations = {
  loading: (state, loading) ->
    state.loading = loading

  set: (state, banners) ->
    state.all = banners
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
