/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ScopeValidateTokenBody model module.
 * @module model/ScopeValidateTokenBody
 * @version v1
 */
export class ScopeValidateTokenBody {
  /**
   * Constructs a new <code>ScopeValidateTokenBody</code>.
   * @alias module:model/ScopeValidateTokenBody
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ScopeValidateTokenBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ScopeValidateTokenBody} obj Optional instance to populate.
   * @return {module:model/ScopeValidateTokenBody} The populated <code>ScopeValidateTokenBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ScopeValidateTokenBody();
      if (data.hasOwnProperty('login'))
        obj.login = ApiClient.convertToType(data['login'], 'String');
      if (data.hasOwnProperty('token_code'))
        obj.token_code = ApiClient.convertToType(data['token_code'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} login
 */
ScopeValidateTokenBody.prototype.login = undefined;

/**
 * @member {String} token_code
 */
ScopeValidateTokenBody.prototype.token_code = undefined;

