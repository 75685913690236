/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The Transaction model module.
 * @module model/Transaction
 * @version v1
 */
export class Transaction {
  /**
   * Constructs a new <code>Transaction</code>.
   * @alias module:model/Transaction
   * @class
   * @param id {String} 
   * @param amount_in_cents {Number} 
   * @param status {String} 
   * @param provider {String} 
   * @param provider_data {Object} 
   * @param provider_reference {String} 
   */
  constructor(id, amount_in_cents, status, provider, provider_data, provider_reference) {
    this.id = id;
    this.amount_in_cents = amount_in_cents;
    this.status = status;
    this.provider = provider;
    this.provider_data = provider_data;
    this.provider_reference = provider_reference;
  }

  /**
   * Constructs a <code>Transaction</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Transaction} obj Optional instance to populate.
   * @return {module:model/Transaction} The populated <code>Transaction</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Transaction();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('amount_in_cents'))
        obj.amount_in_cents = ApiClient.convertToType(data['amount_in_cents'], 'Number');
      if (data.hasOwnProperty('status'))
        obj.status = ApiClient.convertToType(data['status'], 'String');
      if (data.hasOwnProperty('provider'))
        obj.provider = ApiClient.convertToType(data['provider'], 'String');
      if (data.hasOwnProperty('provider_data'))
        obj.provider_data = ApiClient.convertToType(data['provider_data'], Object);
      if (data.hasOwnProperty('provider_reference'))
        obj.provider_reference = ApiClient.convertToType(data['provider_reference'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
Transaction.prototype.id = undefined;

/**
 * @member {Number} amount_in_cents
 */
Transaction.prototype.amount_in_cents = undefined;

/**
 * @member {String} status
 */
Transaction.prototype.status = undefined;

/**
 * @member {String} provider
 */
Transaction.prototype.provider = undefined;

/**
 * @member {Object} provider_data
 */
Transaction.prototype.provider_data = undefined;

/**
 * @member {String} provider_reference
 */
Transaction.prototype.provider_reference = undefined;

