
export default {
  computed: {
    debtorcard: ->
      @$store.state.layout.debtorcard_detail.card

    supplier: ->
      @$store.state.layout.debtorcard_detail.supplier

    is_visible: {
      get: -> !!@debtorcard?.supplier_id && !!@supplier?.id
      set: -> @$store.commit('layout/hide_debtorcard_topup_detail')
    }
  }

  methods: {
    dismiss: ->
      @$store.commit('layout/hide_debtorcard_topup_detail')
  }
}
