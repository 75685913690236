/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {BrandJson} from './BrandJson';
import {PackJson} from './PackJson';
import {RelationJson} from './RelationJson';
import {SellableJson} from './SellableJson';
import {UnitJson} from './UnitJson';

/**
 * The ProductShowJson model module.
 * @module model/ProductShowJson
 * @version v1
 */
export class ProductShowJson {
  /**
   * Constructs a new <code>ProductShowJson</code>.
   * @alias module:model/ProductShowJson
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ProductShowJson</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ProductShowJson} obj Optional instance to populate.
   * @return {module:model/ProductShowJson} The populated <code>ProductShowJson</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ProductShowJson();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('image_url'))
        obj.image_url = ApiClient.convertToType(data['image_url'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('visibility'))
        obj.visibility = ApiClient.convertToType(data['visibility'], 'String');
      if (data.hasOwnProperty('brands'))
        obj.brands = ApiClient.convertToType(data['brands'], [BrandJson]);
      if (data.hasOwnProperty('packs'))
        obj.packs = ApiClient.convertToType(data['packs'], [PackJson]);
      if (data.hasOwnProperty('relations'))
        obj.relations = ApiClient.convertToType(data['relations'], [RelationJson]);
      if (data.hasOwnProperty('sellables'))
        obj.sellables = ApiClient.convertToType(data['sellables'], [SellableJson]);
      if (data.hasOwnProperty('units'))
        obj.units = ApiClient.convertToType(data['units'], [UnitJson]);
      if (data.hasOwnProperty('product_types'))
        obj.product_types = ApiClient.convertToType(data['product_types'], ['String']);
      if (data.hasOwnProperty('catalog_paths'))
        obj.catalog_paths = ApiClient.convertToType(data['catalog_paths'], ['String']);
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
ProductShowJson.prototype.id = undefined;

/**
 * @member {String} image_url
 */
ProductShowJson.prototype.image_url = undefined;

/**
 * @member {String} name
 */
ProductShowJson.prototype.name = undefined;

/**
 * @member {String} visibility
 */
ProductShowJson.prototype.visibility = undefined;

/**
 * @member {Array.<module:model/BrandJson>} brands
 */
ProductShowJson.prototype.brands = undefined;

/**
 * @member {Array.<module:model/PackJson>} packs
 */
ProductShowJson.prototype.packs = undefined;

/**
 * @member {Array.<module:model/RelationJson>} relations
 */
ProductShowJson.prototype.relations = undefined;

/**
 * @member {Array.<module:model/SellableJson>} sellables
 */
ProductShowJson.prototype.sellables = undefined;

/**
 * @member {Array.<module:model/UnitJson>} units
 */
ProductShowJson.prototype.units = undefined;

/**
 * @member {Array.<String>} product_types
 */
ProductShowJson.prototype.product_types = undefined;

/**
 * @member {Array.<String>} catalog_paths
 */
ProductShowJson.prototype.catalog_paths = undefined;

