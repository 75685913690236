/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ManagerApiV1ImportsCreateSuccessResponseImport model module.
 * @module model/ManagerApiV1ImportsCreateSuccessResponseImport
 * @version v1
 */
export class ManagerApiV1ImportsCreateSuccessResponseImport {
  /**
   * Constructs a new <code>ManagerApiV1ImportsCreateSuccessResponseImport</code>.
   * @alias module:model/ManagerApiV1ImportsCreateSuccessResponseImport
   * @class
   * @param id {String} 
   * @param imported_at {String} 
   * @param status {String} 
   * @param content_type {String} 
   */
  constructor(id, imported_at, status, content_type) {
    this.id = id;
    this.imported_at = imported_at;
    this.status = status;
    this.content_type = content_type;
  }

  /**
   * Constructs a <code>ManagerApiV1ImportsCreateSuccessResponseImport</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ManagerApiV1ImportsCreateSuccessResponseImport} obj Optional instance to populate.
   * @return {module:model/ManagerApiV1ImportsCreateSuccessResponseImport} The populated <code>ManagerApiV1ImportsCreateSuccessResponseImport</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ManagerApiV1ImportsCreateSuccessResponseImport();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('import_type'))
        obj.import_type = ApiClient.convertToType(data['import_type'], 'String');
      if (data.hasOwnProperty('imported_at'))
        obj.imported_at = ApiClient.convertToType(data['imported_at'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = ApiClient.convertToType(data['status'], 'String');
      if (data.hasOwnProperty('content_type'))
        obj.content_type = ApiClient.convertToType(data['content_type'], 'String');
      if (data.hasOwnProperty('supplier_id'))
        obj.supplier_id = ApiClient.convertToType(data['supplier_id'], 'String');
      if (data.hasOwnProperty('supplier_name'))
        obj.supplier_name = ApiClient.convertToType(data['supplier_name'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
ManagerApiV1ImportsCreateSuccessResponseImport.prototype.id = undefined;

/**
 * @member {String} import_type
 */
ManagerApiV1ImportsCreateSuccessResponseImport.prototype.import_type = undefined;

/**
 * @member {String} imported_at
 */
ManagerApiV1ImportsCreateSuccessResponseImport.prototype.imported_at = undefined;

/**
 * @member {String} status
 */
ManagerApiV1ImportsCreateSuccessResponseImport.prototype.status = undefined;

/**
 * @member {String} content_type
 */
ManagerApiV1ImportsCreateSuccessResponseImport.prototype.content_type = undefined;

/**
 * @member {String} supplier_id
 */
ManagerApiV1ImportsCreateSuccessResponseImport.prototype.supplier_id = undefined;

/**
 * @member {String} supplier_name
 */
ManagerApiV1ImportsCreateSuccessResponseImport.prototype.supplier_name = undefined;

