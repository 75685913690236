state = {
  loading: true
}

getters = {}

actions = {}

mutations = {
  page_loaded: (state) ->
    state.loading = false
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}