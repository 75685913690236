class GoogleAnalytics

  constructor: ({ @config }) ->
    # @debug_events = [] unless @tracking_enabled()
    @initialize_google_analytics()
    console.warn 'SERVICE.GA.tracking:', if @tracking_enabled() then 'live' else 'debug'

  initialize_google_analytics: ->
    window.dataLayer = window.dataLayer || []
    @gtag = -> dataLayer.push arguments
    @gtag('js', new Date())
    config = {
      allow_google_signals: false
      send_page_view: false
      debug_mode: !@tracking_enabled()
    }
    @gtag('config', @config.ga_measurement_id, config)

  track: (event_name, event_params) ->
    if @user_is_demo()
      console.log 'SERVICE.GA.track: demo user, event logged instead', event_name, event_params
    else
      @gtag('event', event_name, event_params)

  user_is_demo: ->
    false
    # window.account.current() and window.account.current().is_demo

  tracking_enabled: ->
    @config.env == 'production'

export default new GoogleAnalytics({
  config: {
    env: process.env.NODE_ENV,
    ga_measurement_id: process.env.VUE_APP_GA_MEASUREMENT_ID
  }
})
