/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {SellableJson} from './SellableJson';

/**
 * The OrderItem model module.
 * @module model/OrderItem
 * @version v1
 */
export class OrderItem {
  /**
   * Constructs a new <code>OrderItem</code>.
   * @alias module:model/OrderItem
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>OrderItem</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/OrderItem} obj Optional instance to populate.
   * @return {module:model/OrderItem} The populated <code>OrderItem</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new OrderItem();
      if (data.hasOwnProperty('barcode'))
        obj.barcode = ApiClient.convertToType(data['barcode'], 'String');
      if (data.hasOwnProperty('brand'))
        obj.brand = ApiClient.convertToType(data['brand'], 'String');
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('image_url'))
        obj.image_url = ApiClient.convertToType(data['image_url'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('offer_id'))
        obj.offer_id = ApiClient.convertToType(data['offer_id'], 'String');
      if (data.hasOwnProperty('pack'))
        obj.pack = ApiClient.convertToType(data['pack'], 'String');
      if (data.hasOwnProperty('qty'))
        obj.qty = ApiClient.convertToType(data['qty'], 'Number');
      if (data.hasOwnProperty('sellable'))
        obj.sellable = SellableJson.constructFromObject(data['sellable']);
      if (data.hasOwnProperty('supplier_id'))
        obj.supplier_id = ApiClient.convertToType(data['supplier_id'], 'String');
      if (data.hasOwnProperty('total'))
        obj.total = ApiClient.convertToType(data['total'], 'String');
      if (data.hasOwnProperty('total_in_cents'))
        obj.total_in_cents = ApiClient.convertToType(data['total_in_cents'], 'Number');
      if (data.hasOwnProperty('unit'))
        obj.unit = ApiClient.convertToType(data['unit'], 'String');
      if (data.hasOwnProperty('unit_price'))
        obj.unit_price = ApiClient.convertToType(data['unit_price'], 'String');
      if (data.hasOwnProperty('unit_price_in_cents'))
        obj.unit_price_in_cents = ApiClient.convertToType(data['unit_price_in_cents'], 'Number');
      if (data.hasOwnProperty('base_price_in_cents'))
        obj.base_price_in_cents = ApiClient.convertToType(data['base_price_in_cents'], 'Number');
      if (data.hasOwnProperty('packed_at'))
        obj.packed_at = ApiClient.convertToType(data['packed_at'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} barcode
 */
OrderItem.prototype.barcode = undefined;

/**
 * @member {String} brand
 */
OrderItem.prototype.brand = undefined;

/**
 * @member {String} id
 */
OrderItem.prototype.id = undefined;

/**
 * @member {String} image_url
 */
OrderItem.prototype.image_url = undefined;

/**
 * @member {String} name
 */
OrderItem.prototype.name = undefined;

/**
 * @member {String} offer_id
 */
OrderItem.prototype.offer_id = undefined;

/**
 * @member {String} pack
 */
OrderItem.prototype.pack = undefined;

/**
 * @member {Number} qty
 */
OrderItem.prototype.qty = undefined;

/**
 * @member {module:model/SellableJson} sellable
 */
OrderItem.prototype.sellable = undefined;

/**
 * @member {String} supplier_id
 */
OrderItem.prototype.supplier_id = undefined;

/**
 * @member {String} total
 */
OrderItem.prototype.total = undefined;

/**
 * @member {Number} total_in_cents
 */
OrderItem.prototype.total_in_cents = undefined;

/**
 * @member {String} unit
 */
OrderItem.prototype.unit = undefined;

/**
 * @member {String} unit_price
 */
OrderItem.prototype.unit_price = undefined;

/**
 * @member {Number} unit_price_in_cents
 */
OrderItem.prototype.unit_price_in_cents = undefined;

/**
 * @member {Number} base_price_in_cents
 */
OrderItem.prototype.base_price_in_cents = undefined;

/**
 * @member {String} packed_at
 */
OrderItem.prototype.packed_at = undefined;

