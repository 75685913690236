import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@fortawesome/fontawesome-free/css/all.css'
import config from '@/application.yml'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'fa'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: config.theme.primary || '#41afa5',
        primary_text: config.theme.primary_text || '#000',
        secondary: config.theme.secondary || '#424242',
        secondary_text: config.theme.secondary_text || '#FFF',
        accent: config.theme.accent || '#82B1FF',
        error: config.theme.error || '#E11D48',
        info: config.theme.info || '#2196F3',
        success: config.theme.success || '#4CAF50',
        warning: config.theme.warning || '#FFC107'
        light: '#f8f8f8'
        promotion: config.theme.promotion || '#dc3545'
        promotion_text: config.theme.promotion_text || '#fff'
        green: '#27AE60',
        blue: '#2F80ED',
        muted: '#E5E7EB'
        muted_text: '#374151'
      },
      dark: {}
    },
    options: { customProperties: true }
  }
})
