/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The OrderReview model module.
 * @module model/OrderReview
 * @version v1
 */
export class OrderReview {
  /**
   * Constructs a new <code>OrderReview</code>.
   * @alias module:model/OrderReview
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>OrderReview</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/OrderReview} obj Optional instance to populate.
   * @return {module:model/OrderReview} The populated <code>OrderReview</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new OrderReview();
      if (data.hasOwnProperty('rating'))
        obj.rating = ApiClient.convertToType(data['rating'], 'Number');
      if (data.hasOwnProperty('comment'))
        obj.comment = ApiClient.convertToType(data['comment'], 'String');
    }
    return obj;
  }
}

/**
 * @member {Number} rating
 */
OrderReview.prototype.rating = undefined;

/**
 * @member {String} comment
 */
OrderReview.prototype.comment = undefined;

