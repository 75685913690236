/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The OrderModification model module.
 * @module model/OrderModification
 * @version v1
 */
export class OrderModification {
  /**
   * Constructs a new <code>OrderModification</code>.
   * @alias module:model/OrderModification
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>OrderModification</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/OrderModification} obj Optional instance to populate.
   * @return {module:model/OrderModification} The populated <code>OrderModification</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new OrderModification();
      if (data.hasOwnProperty('action'))
        obj.action = ApiClient.convertToType(data['action'], 'String');
      if (data.hasOwnProperty('order_item_id'))
        obj.order_item_id = ApiClient.convertToType(data['order_item_id'], 'String');
      if (data.hasOwnProperty('qty'))
        obj.qty = ApiClient.convertToType(data['qty'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} action
 */
OrderModification.prototype.action = undefined;

/**
 * @member {String} order_item_id
 */
OrderModification.prototype.order_item_id = undefined;

/**
 * @member {String} qty
 */
OrderModification.prototype.qty = undefined;

