
import { VOffline } from 'v-offline';

export default {
  components: { VOffline }

  data: -> {
    online: true
    alert_type: 'error'
    message: @$t('alert_connection_lost')
  }

  mounted: ->
    @indicate_offline() unless window.navigator.onLine

  methods: {
    on_network_change: (status) ->
      if status
        @alert_type = 'success'
        @message = @$t('alert_connection_restored')
        setTimeout =>
          @online = true
        , 1000
      else
        @indicate_offline()

    indicate_offline: ->
      @alert_type = 'error'
      @message = @$t('alert_connection_lost')
      @online = false
  }
}
