/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {ClientConfigShipping} from './ClientConfigShipping';

/**
 * The ClientConfig model module.
 * @module model/ClientConfig
 * @version v1
 */
export class ClientConfig {
  /**
   * Constructs a new <code>ClientConfig</code>.
   * @alias module:model/ClientConfig
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ClientConfig</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ClientConfig} obj Optional instance to populate.
   * @return {module:model/ClientConfig} The populated <code>ClientConfig</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ClientConfig();
      if (data.hasOwnProperty('bags'))
        obj.bags = ApiClient.convertToType(data['bags'], 'Boolean');
      if (data.hasOwnProperty('bag_price_in_cents'))
        obj.bag_price_in_cents = ApiClient.convertToType(data['bag_price_in_cents'], 'Number');
      if (data.hasOwnProperty('shipping'))
        obj.shipping = ApiClient.convertToType(data['shipping'], [ClientConfigShipping]);
    }
    return obj;
  }
}

/**
 * @member {Boolean} bags
 */
ClientConfig.prototype.bags = undefined;

/**
 * @member {Number} bag_price_in_cents
 */
ClientConfig.prototype.bag_price_in_cents = undefined;

/**
 * @member {Array.<module:model/ClientConfigShipping>} shipping
 */
ClientConfig.prototype.shipping = undefined;

