/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {OrderPrepareJob} from './OrderPrepareJob';

/**
 * The InlineResponse2001 model module.
 * @module model/InlineResponse2001
 * @version v1
 */
export class InlineResponse2001 {
  /**
   * Constructs a new <code>InlineResponse2001</code>.
   * @alias module:model/InlineResponse2001
   * @class
   * @param status {String} 
   * @param job {module:model/OrderPrepareJob} 
   */
  constructor(status, job) {
    this.status = status;
    this.job = job;
  }

  /**
   * Constructs a <code>InlineResponse2001</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse2001} obj Optional instance to populate.
   * @return {module:model/InlineResponse2001} The populated <code>InlineResponse2001</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse2001();
      if (data.hasOwnProperty('status'))
        obj.status = ApiClient.convertToType(data['status'], 'String');
      if (data.hasOwnProperty('job'))
        obj.job = OrderPrepareJob.constructFromObject(data['job']);
    }
    return obj;
  }
}

/**
 * @member {String} status
 */
InlineResponse2001.prototype.status = undefined;

/**
 * @member {module:model/OrderPrepareJob} job
 */
InlineResponse2001.prototype.job = undefined;

