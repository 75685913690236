/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The UnitJson model module.
 * @module model/UnitJson
 * @version v1
 */
export class UnitJson {
  /**
   * Constructs a new <code>UnitJson</code>.
   * @alias module:model/UnitJson
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>UnitJson</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UnitJson} obj Optional instance to populate.
   * @return {module:model/UnitJson} The populated <code>UnitJson</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UnitJson();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('measurement'))
        obj.measurement = ApiClient.convertToType(data['measurement'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
UnitJson.prototype.id = undefined;

/**
 * @member {String} name
 */
UnitJson.prototype.name = undefined;

/**
 * @member {String} measurement
 */
UnitJson.prototype.measurement = undefined;

