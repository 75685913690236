import FilterEngine from './base_filter/filter_engine'
import BrandFilterEngine from './brand_filter/brand_filter_engine'
import UnitFilterEngine from './unit_filter/unit_filter_engine'
import PackFilterEngine from './pack_filter/pack_filter_engine'
import RelationFilterEngine from './relation_filter/relation_filter_engine'

class FilterFactory

  make: (payload) ->
    switch payload.filter_type
      when 'brand' then new BrandFilterEngine(payload)
      when 'unit' then new UnitFilterEngine(payload)
      when 'pack' then new PackFilterEngine(payload)
      when 'relation' then new RelationFilterEngine(payload)

export default new FilterFactory
