
import TopToolbar from '@/components/top_toolbar.vue'
import AccountRequiredModal from '@/components/account_required_modal'
import AddToCartModal from '@/components/add_to_cart_modal.vue'
import CheckoutOutro from '@/components/checkout/outro_modal'
import OpenOrderIndicator from '@/components/open_order_indicator'
import OrderReviewModal from '@/components/order_review_modal'
import MarketSupplierBar from '@/components/market_supplier_bar'
import DebtorcardTopupModal from '@/components/debtorcard_topup_modal'
import OrderTimeoutModal from '@/components/modals/order_timeout_modal'
import OfflineIndicator from '@/components/offline_indicator'

export default {
  data: -> { loading_application_config: false }
  components: {
    TopToolbar,
    AddToCartModal,
    CheckoutOutro,
    OpenOrderIndicator,
    OrderReviewModal,
    OrderTimeoutModal,
    MarketSupplierBar,
    DebtorcardTopupModal,
    OfflineIndicator
  },

  created: ->
    @fetch_demo_config() if @$store.state.application.features.demo_station

  computed: {
    booting: -> @$store.state.bootstate.loading
    is_market: -> !@$store.getters['application/is_agency']
    is_catalog_page: ->
      new RegExp([
        'catalog_root',
        'category',
        'product',
        'promotions',
        'search',
        'stokvel',
        'combos',
        'basket'
      ].join('|')).test(@$route.name)

  }

  watch: {
    booting: (booting) ->
      document.getElementById('splash-wrapper').remove() if !booting
  }

  methods: {
    fetch_demo_config: ->
      @loading_application_config = true
      @$store.dispatch('application/fetch_config')
      .then (config) =>
        if config
          Object.assign(@$vuetify.theme.themes.light, config.theme)
          @inject_custom_css()
      .catch(console.error)
      .finally =>
        @$router.go_home() if !@$store.getters['application/is_agency']
        @loading_application_config = false

    inject_custom_css: ->
      console.log 'injecting demo store custom css:', @$store.state.application.theme.custom_css
      if @$store.state.application.theme.custom_css
        style = document.createElement('style')
        style.type = 'text/css'
        style.innerHTML = @$store.state.application.theme.custom_css
        document.head.appendChild(style)
  }

}
