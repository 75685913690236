import FilterEngine from '../base_filter/filter_engine'

export default class BrandFilterEngine extends FilterEngine

  constructor: (config) ->
    super(config)
    @filter_manager = config.controller
    @brands = config.product.brands

  select_handoff: (sellables) ->
    @filter_manager.sort_relation_filters sellables, (relations) =>
      @filter_manager.initialize_relation_filters relations, =>
        if @successor
          @successor.update(sellables)
        else
          @controller.return_sellable(sellables[0])

  build_option: (item) ->
    {
      id: item["#{@type}_id"],
      type: @type,
      name: item["#{@type}"],
      image_url: @build_brand_image_url(item)
    }

  build_brand_image_url: (item) ->
    brand = @brands.find (brand) -> brand.id == item.brand_id
    if brand then brand.image_url else item.image_url
